import React,{useEffect} from "react";

const HowMuchIncomeDoINeedtoBuyaHouse = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    return (
        <>
            <div className="row justify-content-center mt-3">
                <div className="col-md-8">
                    <h5>How Much Income Do I Need to Buy a House?</h5>
                    
                    <p className="mt-3">If you are looking to buy a home, then one of the biggest questions you probably have is—do I make enough money to buy a house? The truth is that the answer to this question isn’t as simple and straightforward as many people might think.</p>
                    
                    <p>The reason for this is because lenders take much more into consideration than just your income when approving or denying your mortgage application. Your income most certainly plays a role, but so does your debt-to-income (DTI) ratio, your credit history, and how much money you have saved to put down on the home, as well as other factors. </p>
                    
                    <p><strong>The Best Place to Start – Calculate Your DTI </strong></p>
                    
                    <p>Your debt-to-income ratio is, in a lot of ways, even more important to lenders than your income because if you already have a high debt threshold, then it reduces the likelihood that you will be able to safely satisfy your loan obligations. To qualify for a conventional mortgage, meaning one that is backed by Fannie Mae, your DTI should be 36% or lower. It can be as high as 45%, but in this case, you will need to meet certain credit score and financial reserve requirements.</p>
                    
                    <p>Knowing where your DTI currently stands will let you know what action you need to take to improve your chances of qualifying for the mortgage you want.</p>
                    
                    <p>To calculate your debt-to-income ratio, add up all your monthly debt payments and divide that amount by your gross monthly income. Then, multiply that total by 100 and that answer will be your DTI ratio. </p>
                    
                    <p><strong>Check Your Credit</strong></p>
                    
                    <p>Checking your credit is also an important early step because this will allow you to see where your score stands. It will also give you the opportunity to see if there are any mistakes included on your report that once removed might improve your score. To qualify for a conventional mortgage, you want your score to be at least 640. </p>
                    
                    <p>If your DTI is within the acceptable threshold for the type of mortgage you want and your credit history is in good shape, then your next step should be to get preapproved for a mortgage.</p>
                    
                    <p><strong>Get Preapproved to See How Much Home Your Income Can Afford </strong></p>
                    
                    <p>If you are still unsure if your current income is enough for you to buy a home, then getting preapproved by your lender will help you find out how much you can expect to borrow based solely on your income and credit history. </p>
                    
                    <p>After the lender reviews your income and credit report, they will give you a preapproval letter that will give you an accurate estimate of how much home you can afford. This is an important step because it gives you a target budget to work with, so you can avoid wasting time looking at houses outside of your realistic ability to afford them.</p>
                    
                    <p>To give you an idea of how much home your income can afford, keep in mind that most lenders do not want their borrowers spending more than 28% of their income on their mortgage. </p>
                    
                    <p>It is also important to note that a preapproval letter is not the same thing as being prequalified for a mortgage. A preapproval letter basically states that if nothing changes regarding your income, work history, or credit report, then you should be able to qualify for a mortgage amount within the preapproved limit.</p>
                    
                    <p>To actually qualify for the mortgage you want, the process is much more involved, and the lender will require a wider range of supporting documents beyond just your financial statements and credit report.</p>
                    
                    <p><strong>What Types of Income Can Be Included?</strong></p>
                    
                    <p>If you are employed on a company’s payroll or self-employed, then you will need to submit the required documentation in your application to provide proof of income. For payroll employees, this means the prior month’s pay stubs and W-2s. For self-employed professionals, this usually means your tax returns for the last two years.</p>
                    
                    <p>However, lenders don't just take your base salary into consideration when processing your home loan application. They also accept other forms of income, such as:</p>
                    
                    <ul>
                        <li>Alimony and/or child support payments</li>
                        <li>Commissions</li>
                        <li>Investment income</li>
                        <li>Military benefits</li>
                        <li>Social Security income</li>
                        <li>And more</li>
                    </ul>
                    
                    <p>Fannie Mae lists 25 acceptable types of non-employment income, but to be factored into your overall income calculation, the income you include needs to be consistent and the appropriate documents need to be submitted to support the income claims. </p>
                </div>
            </div>
        </>
    );
}

export default HowMuchIncomeDoINeedtoBuyaHouse;
