import React from "react";
import Footer from "../Components/Footer";
import ReactGA from 'react-ga4';

export default class Email extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.runeHawkTalon();

        ReactGA.initialize(process.env.REACT_APP_GA);
        ReactGA.send({ hitType: 'pageview', page: '/email'});

        ReactGA.event({
            category: 'Step 11, Email',
            action: 'Step 11, Email',
            label: 'Step 11, Email'
        });
    }

    submitStep = () =>{
        let isError = false;

        if (!this.props.values.email.length || !(/\S+@\S+\.\S+/.test(this.props.values.email))) {
            this.props.setError('email');
            isError = true;
        }

        if (!isError) {
            this.props.next();
        }
    }

    render () {
        return (
            <>
                <form onSubmit={e => { e.preventDefault(); }}>
                    <input type="hidden" name="universal_leadid" value="" id="leadid_token"/>
            <div className="section-question">
                <div className="row question">
                    <div className="col-md-2"/>
                    <div className="col-md-8 text-center">
                        {this.props.values.publisher_id === "1330" ? <h5>Call Us: <a style={{textDecoration: "none"}} href="tel:+18665051308">866-505-1308</a></h5>: ""}
                        <p>What's your email?</p>
                        <small>We take privacy seriously. No spam!</small>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-md-4"/>
                    <div className="col-md-4 input-holder">
                        <input
                            type="text"
                            className={`form-control border-input ${!this.props.isError('email')  ? '' : 'invalid'}`}
                            placeholder="Email Address"
                            value={this.props.values.email}
                            onChange={this.props.handleChange('email')}
                        />
                        {this.props.isError('email') &&
                        <div className="invalid-feedback d-block">
                            Please enter your email address.
                        </div>
                        }
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4"/>
                    <div className="col-md-4 text-center mt-5 mb-5">
                        <a className="text-black-50 pointer-cursor" style={{paddingRight:20}} onClick={this.props.back}>Back</a>
                        <button type="button" onClick={this.submitStep} className="btn-block btn btn-success btn-lg btn-text-white btn-350">Next >
                        </button>
                    </div>
                </div>
            </div>
                </form>
                <Footer/>
                <div className="m-5 text-center" style={{fontSize:13}}>
                    <div className="mt-2">© <span>{new Date().getFullYear()}</span> FedRateOptions.com</div>
                </div>
                </>
        )
    }
}
