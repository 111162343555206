import React from "react";
import ScrollToTop from "../Components/ScrollToTop";
import Footer from "../Components/Footer";
import ReactGA from 'react-ga4';

export default class CreditScore extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.runeHawkTalon();

        ReactGA.initialize(process.env.REACT_APP_GA);
        ReactGA.send({ hitType: 'pageview', page: '/creditscore'});

        ReactGA.event({
            category: 'Step 4, Credit rating',
            action: 'Step 4, Credit rating',
            label: 'Step 4, Credit rating'
        });
    }

    submitStep = (value) =>{
        this.props.manualHandleChange('creditScore',value)

        this.props.next();

        ScrollToTop();
    }

    render () {
        return (
            <>
                <form>
                    <input type="hidden" name="universal_leadid" value="" id="leadid_token"/>
                <div className="section-question">
                    <div className="row question">
                        <div className="col-md-2"/>
                        <div className="col-md-8 text-center">
                            {this.props.values.publisher_id === "1330" ? <h5>Call Us: <a style={{textDecoration: "none"}} href="tel:+18665051308">866-505-1308</a></h5>: ""}
                            <p>Estimate Your Credit Rating</p>
                            <small>Most people have a 'Good' or 'Excellent' rating</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-1"/>
                        <div className="col-md-10">
                            <div className="buttons credit-score">
                                <button className="excellent" type="button" onClick={() => this.submitStep('Excellent')}>
                                    <span className="icon"/>
                                    <span className="text">Excellent (701+)</span>
                                </button>

                                <button className="good" type="button" onClick={() => this.submitStep('Good')}>
                                    <span className="icon"/>
                                    <span className="text">Good (640-700)</span>
                                </button>

                                <button className="average" type="button" onClick={() => this.submitStep('Fair')}>
                                    <span className="icon"/>
                                    <span className="text">Fair (560-639)</span>
                                </button>

                                <button className="poor" type="button" onClick={() => this.submitStep('Poor')}>
                                    <span className="icon"/>
                                    <span className="text">Poor (559 or less)</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4"/>
                        <div className="col-md-4 text-center mt-2 mb-2">
                            <a className="text-black-50 pointer-cursor" style={{paddingRight:20}} onClick={this.props.back}>Back</a>
                        </div>
                    </div>
                </div>
                </form>
                <Footer/>
                <div className="m-5 text-center" style={{fontSize:13}}>
                    <div className="mt-2">© <span>{new Date().getFullYear()}</span> FedRateOptions.com</div>
                </div>
            </>
        )
    }
}
