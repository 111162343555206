import React from "react";
import NumberFormat from "react-number-format";
import Footer from "../Components/Footer";
import TermsOfUse from "../Components/TermsOfUse";
import PrivacyPolicy from "../Components/PrivacyPolicy";
import PartnerList from "../Components/PartnerList";
import ReactGA from 'react-ga4';

export default class Phone extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ppIsOpen: false };
    }

    setPpIsOpen = (val) => {
        this.setState({ ppIsOpen: val });
    }

    componentDidMount() {
        window.runeHawkTalon();

        ReactGA.initialize(process.env.REACT_APP_GA);
        ReactGA.send({ hitType: 'pageview', page: '/phone'});

        ReactGA.event({
            category: 'Step 12, Phone',
            action: 'Step 12, Phone',
            label: 'Step 12, Phone'
        });
    }

    submitStep = () =>{
        let isError = false;

        if (!this.props.values.phone.length || this.props.values.phone.length !== 10) {
            this.props.setError('phone');
            isError = true;
        }

        if (this.props.values.phone.length === 10) {
            if (this.props.values.phone[0] === "0" || this.props.values.phone[0] === "1") {
                this.props.setError('phone');
                isError = true;
            }
        }

        if (!isError) {
            const params = new URLSearchParams(window.location.search);
            params.set('SourceID', this.props.values.publisher_id);
            params.set('ExtClickID', window.leadId);
            params.set('zipcode', this.props.values.zip);
            params.set('State', this.props.values.state);
            params.set('creditscore', this.props.values.creditScore);
            params.set('loanbalance', this.props.values.remainingMortgageBalance);
            params.set('propertyvalue', this.props.values.estimatedHomeValue);
            params.set('PropertyUse', "");
            params.set('MediaChannel', "");
            params.set('EmploymentStatus', "");

            // MAO PII added 

            params.set('FName', encodeURIComponent(this.props.values.firstName));
            params.set('LName', encodeURIComponent(this.props.values.lastName));
            params.set('Email', this.props.values.email);
            params.set('Phone', encodeURIComponent(this.props.values.phone));
            params.set('City', encodeURIComponent(this.props.values.city));
            let maoAddress = encodeURIComponent(this.props.values.address) + "," + encodeURIComponent(" " + this.props.values.city) + "," + encodeURIComponent(" " + this.props.values.state + " " + this.props.values.zip);
            params.set('Address', maoAddress);

            if (this.props.values.cashOut === 'No') {
                params.set('CashOut', 'No');
            }else {
                params.set('CashOut', 'Yes');
            }

            if (this.props.values.activeMilitary === 'Y') {
                params.set('MilitaryStatus', 'Yes');
            }else {
                params.set('MilitaryStatus', 'No');
            }

            if (this.props.values.employmentStatus === 'Employed') {
                params.set('EmploymentStatus', 'Employed');
            }else if (this.props.values.employmentStatus === 'Self-Employed'){
                params.set('EmploymentStatus', 'Self-Employed');
            }else if (this.props.values.employmentStatus === 'Retired'){
                params.set('EmploymentStatus', 'Retired');
            }else if (this.props.values.employmentStatus === 'Other'){
                params.set('EmploymentStatus', 'Not Employed');
            }

            if (this.props.values.homeType === 'Single Family') {
                params.set('PropertyType', 'Single Family');
            }else if (this.props.values.homeType === 'Condominium'){
                params.set('PropertyType', 'Condo/Townhome');
            }else if (this.props.values.homeType === 'Multi-Family Dwelling Unit'){
                params.set('PropertyType', 'Multi-Unit');
            }else if (this.props.values.homeType === 'Mobile Home'){
                params.set('PropertyType', 'Mobile');
            }

            params.toString();
            
            let params_string = params.toString();
            if(params_string.indexOf(' ') >= 0) {
                params_string = params_string.replace(/["+"]/g, " ");
                if(params_string.indexOf('%2C') >= 0) {
                    params_string = params_string.replaceAll('%2C', ',');
                }
            }
            // window.history.replaceState({}, '', `${window.location.pathname}?${params.toString().replace(/["+"]/g, " ").replaceAll('%2C', ',')}`);
            window.history.replaceState({}, '', `${window.location.pathname}?${params_string}`);

            this.props.next();
        }
    }

    render () {
        return (
            <>
                <form onSubmit={e => { e.preventDefault(); }}>
                    <input type="hidden" name="universal_leadid" value="" id="leadid_token"/>
            <div className="section-question">
                <div className="row question">
                    <div className="col-md-2"/>
                    <div className="col-md-8 text-center">
                        {this.props.values.publisher_id === "1330" ? <h5>Call Us: <a style={{textDecoration: "none"}} href="tel:+18665051308">866-505-1308</a></h5>: ""}
                        <p>You've Been Matched With Lenders!</p>
                        <small>Complete This Final Step To See Your Savings.</small>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-md-4"/>
                    <div className="col-md-4 input-holder">
                        <NumberFormat
                            className={`form-control border-input ${!this.props.isError('phone') ? '' : 'invalid'}`}
                            placeholder="Phone Number"
                            format="+1 (###) ###-####"
                            mask="_"
                            value={this.props.values.phone}
                            onValueChange={(values) => {
                                const {formattedValue, value} = values;
                                this.props.manualHandleChange('phone',value);
                                this.setState({ phoneCorrect: true });
                            }}
                        />
                        {this.props.isError('phone') &&
                        <div className="invalid-feedback d-block">
                            Please enter your phone number.
                        </div>
                        }
                     
                            
                     
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4"/>
                    <div className="col-md-4 text-center mt-5 mb-5">
                    <label for="leadid_tcpa_disclosure" > 
                    <div className="mt-2" style={{fontSize:13}}>    
                   
                        <input type="hidden" id="leadid_tcpa_disclosure" />By clicking the button below, you acknowledge, consent, and agree to our terms at the bottom of this page.</div>
                        <input type="button" style={{paddingRight:20, border:"none", textDecoration:"underline", color:"rgba(0,0,0,.5)", backgroundColor:"transparent" }} onClick={this.props.back} value="Back" />
                        {/* <a className="text-black-50 pointer-cursor" style={{paddingRight:20}} onClick={this.props.back}>Back</a> */}
                        <input type="button" onClick={this.submitStep} className="btn-block btn btn-success btn-lg btn-text-white btn-350" value="View Lenders" />
                        <div className="mt-2" style={{fontSize:13}}>
                                By clicking "View Lenders" you electronically agree to share your information with up to 6 <PartnerList/> (possibly including Rocket
                                Mortgage) and provide your prior "written" consent for them to contact you
                                (including through automated means; autodialing, text and pre-recorded
                                messaging) via telephone, mobile device (including SMS and MMS) and/or
                                email, even if your telephone number is currently listed on any state,
                                federal or corporate Do Not Call list and you agree to our <TermsOfUse setIsOpenPp={this.setPpIsOpen} /> and <PrivacyPolicy isOpenPp={this.state.ppIsOpen} setIsOpenPp={this.setPpIsOpen} />. You agree
                                that we can share your personal data with third parties, such as our
                                mortgage partners, service providers and other affiliates, and that we
                                can use this data for marketing and analytics, and to make your
                                experience easier. This consent is not a condition of a
                                purchase.
        
                                </div></label>
                    </div>
                </div>
            </div>
                </form>
                <Footer/>
                <div className="m-5 text-center" style={{fontSize:13}}>
                    <div className="mt-2">© <span>{new Date().getFullYear()}</span> FedRateOptions.com</div>
                </div>
                </>
        )
    }
}
