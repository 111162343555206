import React,{useEffect} from "react";

const IncomeRequirements = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    return (
        <>
            <div className="row justify-content-center mt-3">
                <div className="col-md-8">
                    <h5>What are the Income Requirements to Qualify for a Conventional Mortgage?</h5>
                    <p className="mt-3">When applying for a conventional mortgage, there are two primary things that a lender will need to determine about you before you can qualify. These key items include:</p>
                    <ul>
                        <li>1. The likelihood of you repaying your loan</li>
                        <li>2. Your ability to repay your loan</li>
                    </ul>
                    <p>To determine these two factors, the lender will carefully review your credit history to consider the likelihood of you repaying your loan, and your proof of income documents to ensure you earn enough money to repay the loan.</p>
                    <p>While your credit report is relatively straightforward because it is acquired from the credit reporting bureaus, your proof of income may be a little more complicated because of the variety of income requirements needed to qualify for a conventional mortgage. </p>
                    <p>In this guide, you will learn more about the income requirements you will need to meet if you are planning on purchasing a home using a conventional mortgage in the near future.</p>
                    <p><strong>Minimum Income Guidelines to Qualify for a Conventional Mortgage</strong></p>
                    <p>Most lenders that issue conventional mortgages follow Fannie Mae and Freddie Mac’s list of acceptable income documentation. For most borrowers, this includes:</p>
                    <ul>
                        <li>Pay stubs from the past month (for W2 employees)</li>
                        <li>Tax returns for the past two years (for self-employed)</li>
                        <li>Documentation to show how you'll make your down payment</li>
                        <li>A financial statement listing assets and liabilities</li>
                    </ul>
                    <p>Of course, this level of documentation may not be applicable for every borrower or situation. Ultimately, the lender will determine what documentation is warranted.</p>
                    <p>Since every property transaction is unique, the minimum income guidelines will differ for each transaction and buyer. However, lenders will usually require at least the following:</p>
                    <ul>
                        <li>A debt-to-income ratio under 45% (or lower if you do not have great credit)</li>
                        <li>A 640 minimum credit score</li>
                        <li>A down payment of at least 3% (or 20% if you want to avoid paying mortgage insurance)</li>
                    </ul>
                    <p><strong>How to Determine Your Debt-to-Income Ratio (DTI)</strong></p>
                    <p>Fannie Mae's guidelines concerning a borrower's debt-to-income ratio is strict, but not set in stone. The truth is there are a range of variables that determine what a borrower's DTI needs to be to qualify for a conventional mortgage.</p>
                    <p>In paper, Fannie Mae requires borrowers to have a DTI that does not exceed 36 percent of their stable monthly income. But a borrower can still qualify for a mortgage with a DTI as high as 45 percent if they meet certain credit-score and reserve requirements.</p>
                    <p>The rule of thumb remains, however, that it is key to pay off as much of your debt as possible before applying for a conventional mortgage, in order to make your DTI as low as possible.</p>
                    <p>To calculate your debt-to-income ratio, simply add up all your monthly debt payments and divide that amount by your gross monthly income. Then, multiply that total by 100 and that answer will be your DTI ratio.</p>
                    <p><strong>Acceptable Non-Employment Income Types</strong></p>
                    <p>To qualify for a conventional mortgage backed by Fannie Mae, you are not required to have income from standard W-2 or self-employment. There are 25 non-employment income types that Fannie Mae considers acceptable forms of income that can be used in addition to or in place of W-2 or self-employment earnings. These include:</p>
                    <ul>
                        <li>Alimony, Child Support, or Separate Maintenance</li>
                        <li>Automobile Allowance</li>
                        <li>Boarder Income</li>
                        <li>Capital Gains Income</li>
                        <li>Disability Income — Long-Term</li>
                        <li>Employment Offers or Contracts</li>
                        <li>Employment-Related Assets as Qualifying Income</li>
                        <li>Foreign Income</li>
                        <li>Foster-Care Income</li>
                        <li>Housing or Parsonage Allowance</li>
                        <li>Interest and Dividends Income</li>
                        <li>Mortgage Credit Certificates</li>
                        <li>Mortgage Differential Payments Income</li>
                        <li>Non-Occupant Borrower Income</li>
                        <li>Notes Receivable Income</li>
                        <li>Public Assistance Income</li>
                        <li>Retirement, Government Annuity, and Pension Income</li>
                        <li>Royalty Payment Income</li>
                        <li>Schedule K-1 Income</li>
                        <li>Social Security Income</li>
                        <li>Temporary Leave Income</li>
                        <li>Tip Income</li>
                        <li>Trust Income</li>
                        <li>Unemployment Benefits Income</li>
                        <li>VA Benefits Income</li>
                    </ul>
                    <p>If you earn income through one of the above means, you must supply the required documentation to support your income claims. <a className="text-primary" target="_blank" href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B3-Underwriting-Borrowers/Chapter-B3-3-Income-Assessment/Section-B3-3-1-Employment-and-Other-Sources-of-Income/1035647451/B3-3-1-09-Other-Sources-of-Income-12-16-2020.htm#Documentation.20Requirements.20for.20Current.20Receipt.20of.20Income">Learn more about the required documentation needed for the above non-employment income types here.</a> By including non-employment income sources in your income documentation, you can increase your income, lower your DTI, and help yourself to qualify for the home you want.</p>
                </div>
            </div>
        </>
    );
}

export default IncomeRequirements;
