import React from "react";
import Footer from "../Components/Footer";
import ReactGA from 'react-ga4';
/* global google */

export default class Address extends React.Component {
    state = {
        autocomplete : "new-password",
        geocoder : null
    }

    constructor(props) {
        super(props);

        this.autocompleteInput = React.createRef();
        this.autocomplete = null;
        this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
        this.blurAdressField = this.blurAdressField.bind(this);
    }

    autocompleteFocus = () => {
        this.setState({
            autocomplete: "new-password"+Math.random()
        });
    }

    submitStep = () =>{
        let isError = false;
        
        if (!this.props.values.zip.length || !this.props.values.address.length || !this.props.values.city.length || !this.props.values.state.length) {
            this.props.setError('fullAddress');
            isError = true;                  
        }

        if (!isError) {
            this.props.next();
        }
    }

    componentDidMount() {
        this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current);
        this.geocoder = new google.maps.Geocoder();

        this.autocomplete.addListener('place_changed', this.handlePlaceChanged);

        window.runeHawkTalon();

        ReactGA.initialize(process.env.REACT_APP_GA);
        ReactGA.send({ hitType: 'pageview', page: '/address'});

        ReactGA.event({
            category: 'Step 9, Address',
            action: 'Step 9, Address',
            label: 'Step 9, Address'
        });
    }

    blurAdressField = (e) => {
        this.props.manualHandleChange('address',"");
        this.props.manualHandleChange('city',"");
        this.props.manualHandleChange('state',"");
        this.props.manualHandleChange('zip',"");
        this.props.manualHandleChange('fullAddress',e.target.value);
        this.geocoder.geocode({ 'address': e.target.value }, function handleResults(results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                const place = results[0]; 
                if (place && place.address_components && place.formatted_address) {                                   
                    this.props.manualHandleChange('fullAddress', place.formatted_address);
                    this.setState({fullAddressCorrect: true});

                    let streetNumber = false;

                    for (let i = 0; i < place.address_components.length; i++) {
                        let addressType = place.address_components[i].types[0];

                        if (addressType === 'street_number') {
                            this.props.manualHandleChange('address', place.address_components[i]['short_name']);
                            streetNumber = true;
                        }

                        if (addressType === 'route') {
                            this.props.manualHandleChange('address', this.props.values.address + " " + place.address_components[i]['short_name']);
                        }

                        if (addressType === 'locality') {
                            this.props.manualHandleChange('city', place.address_components[i]['short_name']);
                        }

                        if (addressType === 'sublocality_level_1') {
                            this.props.manualHandleChange('city', place.address_components[i]['short_name']);
                        }

                        if (addressType === 'administrative_area_level_1') {
                            this.props.manualHandleChange('state', place.address_components[i]['short_name']);
                        }

                        if (addressType === 'postal_code') {
                            this.props.manualHandleChange('zip', place.address_components[i]['short_name']);
                        }
                    }

                    if (!streetNumber) {
                        this.props.manualHandleChange('address', "");
                    }
                }
            }
        }.bind(this))
    }

    changeFullAddress = (e) => {  
        this.props.manualHandleChange('address',"");
        this.props.manualHandleChange('city',"");
        this.props.manualHandleChange('state',"");
        this.props.manualHandleChange('zip',"");
        this.props.manualHandleChange('fullAddress',e.target.value);
        
    }

    handlePlaceChanged(){
        const place = this.autocomplete.getPlace();        
        if (place && place.address_components && place.formatted_address) {
            this.props.manualHandleChange('fullAddress', place.formatted_address);
            this.setState({fullAddressCorrect: true});

            let streetNumber = false;

            for (let i = 0; i < place.address_components.length; i++) {
                let addressType = place.address_components[i].types[0];

                if (addressType === 'street_number') {
                    this.props.manualHandleChange('address', place.address_components[i]['short_name']);
                    streetNumber = true;
                }

                if (addressType === 'route') {
                    this.props.manualHandleChange('address', this.props.values.address + " " + place.address_components[i]['short_name']);
                }

                if (addressType === 'locality') {
                    this.props.manualHandleChange('city', place.address_components[i]['short_name']);
                }

                if (addressType === 'sublocality_level_1') {
                    this.props.manualHandleChange('city', place.address_components[i]['short_name']);
                }

                if (addressType === 'administrative_area_level_1') {
                    this.props.manualHandleChange('state', place.address_components[i]['short_name']);
                }

                if (addressType === 'postal_code') {
                    this.props.manualHandleChange('zip', place.address_components[i]['short_name']);
                }
            }

            if (!streetNumber) {
                this.props.manualHandleChange('address', "");
            }
        }
    }

    render () {
        return (
            <>
                <form onSubmit={e => { e.preventDefault(); }}>
                    <input type="hidden" name="universal_leadid" value="" id="leadid_token"/>
            <div className="section-question">
                <div className="row question">
                    <div className="col-md-2"/>
                    <div className="col-md-8 text-center">
                        {this.props.values.publisher_id === "1330" ? <h5>Call Us: <a style={{textDecoration: "none"}} href="tel:+18665051308">866-505-1308</a></h5>: ""}
                        <p>What's your property address?</p>
                        <small>For verification only. We do not mail.</small>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-md-4"/>
                    <div className="col-md-4 input-holder">
                        <input type="text" value={this.props.values.fullAddress} className={`border-input form-control ${!this.props.isError('fullAddress')  ? '' : 'invalid'}`} onFocus={this.autocompleteFocus} ref={this.autocompleteInput}  id="autocomplete" onBlur={this.blurAdressField} onChange={this.changeFullAddress} autoComplete={this.state.autocomplete} placeholder="Street address"/>
                        {this.props.isError('fullAddress') &&
                        <div className="invalid-feedback d-block">
                            Please enter correct full address including zip code and street, city, and state.
                        </div>
                        }
                    </div>
                    <div className="col-md-4"/>
                </div>

                <div className="row">
                    <div className="col-md-4"/>
                    <div className="col-md-4 text-center mt-5 mb-5">
                        <a className="text-black-50 pointer-cursor" style={{paddingRight:20}} onClick={this.props.back}>Back</a>
                        <button type="button" onClick={this.submitStep} className="btn-block btn btn-success btn-lg btn-text-white btn-350">Next >
                        </button>
                    </div>
                </div>
            </div>
                </form>
                <Footer/>
                <div className="m-5 text-center" style={{fontSize:13}}>
                    <div className="mt-2">© <span>{new Date().getFullYear()}</span> FedRateOptions.com</div>
                </div>
                </>
        )
    }
}
