import React,{useEffect} from "react";

const EmployedSelfEmployedIncome = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    return (
        <>
            <div className="row justify-content-center mt-3">
                <div className="col-md-8">
                    <h5>Employed vs. Self-Employed Income Requirements for Buying a Home</h5>
                    
                    <p className="mt-3">There is a common misconception that it is harder for someone who is self-employed to qualify for a home mortgage than it is for someone who earns a payroll check. The truth is that self-employed professionals are held to most of the same qualification requirements as payroll employees by lenders. But because self-employment income is different than traditional employment income, lenders do have different income requirements for self-employed borrowers. </p>
                    
                    <p>In this guide, you will learn more about the different income requirements for employed and self-employed home loan applicants.</p>
                    
                    <p><strong>Income Requirements for Employed Home Buyers</strong></p>
                    
                    <p>Along with a borrower’s credit history, which shows their trustworthiness when it comes to repaying their debts, the most important thing a lender wants to know is – if approved, can the borrower afford to repay their mortgage? </p>
                    
                    <p>This is easier for lenders to determine when the borrower is employed at a traditional payroll job because they usually receive the same amount in every paycheck, and they get paid at regular intervals. </p>
                    
                    <p>Since lenders do not typically want their borrowers spending more than 28% of their gross income on their mortgage payment, this can be easily determined by calculating the borrower’s debt-to-income ratio. In most cases, lenders want borrowers to have a DTI of 36% or lower, but depending on certain factors, it may still be possible to get approved for a loan with a DTI as high as 46%.</p>
                    
                    <p>The documents you will need to verify your income with the lender include the following:</p>
                    
                    <ul>
                        <li>Pay stubs from the past 30 days </li>
                        <li>W-2s</li>
                        <li>A financial statement listing assets and liabilities</li>
                        <li>Proof of income for any non-employment income included on the application</li>
                    </ul>
                    
                    <p><strong>Income Requirements for Self-Employed Home Buyers</strong></p>
                    
                    <p>The real reason why it is often considered more difficult for a self-employed professional to get approved for a mortgage is the fact that it can be harder for a lender to determine the reliability of the self-employed borrower’s income. Self-employed income can fluctuate week-to-week, month-to-month, and year-to-year. </p>
                    
                    <p>Therefore, lenders require at least two years of personal and business tax returns (including schedules K-1, 1120, 1120S) from self-employed applicants. The lender averages out the borrower’s annual income between the two years and then divides that number by 12. This gives the lender an idea of what the borrower averages in income month-to-month. This amount is then used to determine their debt-to-income ratio.</p>
                    
                    <p>Additional documents typically required by lenders from self-employed borrowers can include:</p>
                    
                    <ul>
                        <li>Business license</li>
                        <li>Year-to-date profit and loss statement (P&L)</li>
                        <li>Balance sheet</li>
                        <li>Signed CPA letter stating that the borrower is still in business</li>
                    </ul>
                    
                    <p><strong>What Qualifies Someone as Self-Employed?</strong></p>
                    
                    <p>Another common misconception is that only people who own their own businesses are self-employed. The truth is a lender will typically consider a borrower self-employed if any of the following factors apply:</p>
                    
                    <ul>
                        <li>You earn an income but do not receive W-2 tax forms</li>
                        <li>You receive 1099 tax forms</li>
                        <li>You are a contractor or freelancer</li>
                        <li>Most of your income is from dividends and interest</li>
                        <li>At least 25% of your income is from non-employment sources</li>
                        <li>You own 25% or more of a business</li>
                    </ul>
                    
                    <p><strong>Can an Employed and a Self-Employed Get a Joint Mortgage?</strong></p>
                    
                    <p>In today’s world, it is not uncommon for a household to have one spouse who is traditionally employed and another who is self-employed. If the two want to apply for a joint mortgage, most lenders won’t have a problem with it if both parties can meet the income requirements listed above. </p>
                    
                    <p>It is important to note that in such a case, including self-employment income on the loan application isn’t required by law. If the couple can qualify for a mortgage using just the employed’s income, then they do not have to list the self-employed’s income if they do not want to. This can be especially beneficial if, on paper, the self-employment venture has only generated losses up to this point.  </p>
                </div>
            </div>
        </>
    );
}

export default EmployedSelfEmployedIncome;
