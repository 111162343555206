import React,{useEffect} from "react";

const FixedRateMortgageLoans = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    return (
        <>
            <div className="row justify-content-center mt-3">
                <div className="col-md-8">
                    <h5>Fixed-Rate Mortgage Loans</h5>
                    <p className="mt-3">
                        Today’s home buyer has a few different mortgage products to choose from when buying a home, but the fixed-rate mortgage loan continues to be the most popular option out there. In many ways, it’s no wonder—a fixed-rate mortgage provides the homeowner with peace of mind, knowing that their monthly payment will remain the same over the life of their loan.
                    </p>
                    <p>
                        In this article, you will learn all about fixed-rate mortgage loans and how they differ from other options, so you can make the most informed decision about your next home purchase.
                    </p>
                    
                    <p><strong>How a Fixed-Rate Mortgage Works</strong></p>
                    
                    <p>Fixed-rate mortgages are traditionally made available with 30-, 20-, or 15-year terms and ensure the home buyer is responsible for the same monthly payment over the duration of that time. But while the payment remains the same, this doesn’t mean there aren’t changes happening to the principal and interest over the life of the loan. </p>
                    
                    <p>When a homeowner takes out this type of mortgage, they basically pay the interest on their loan first, so only a small portion of their monthly payments ends up being applied to the principal of their loan. This is generally the case over the first half of their mortgage’s life. Once the interest is mostly paid, then the bulk of the monthly mortgage payment starts being applied to the loan’s principal. </p>
                    
                    <p>In a conventional fixed-rate mortgage, once an on-time payment is successfully made, any additional monies paid to the lender before the next payment comes due are applied to the principal of the loan. Thus, making an additional payment to the lender every month can dramatically increase the amount of equity that gets built in the home, while simultaneously accelerating the time it takes for the home to be paid off.</p>
                    
                    <p><strong>Pros and Cons of Fixed-Rate Mortgages</strong></p>
                    
                    <p>The primary advantage of a fixed-rate mortgage is that the borrower is protected against having their monthly payment being affected by any sudden or significant increases in interest rates. This not only makes this type of loan easier for first-time home buyers to understand, but also easier to budget for. Further, because this type of loan can be spread out over 30 years, it typically also offers the borrower the benefit of having the lowest possible monthly payment.</p>
                    
                    <p>The main downside to purchasing a fixed-rate mortgage is that qualifying for one tends to become more difficult when interest rates are high. This is because a high interest rate makes for a higher loan payment. Plus, while taking out a 30-year fixed-rate mortgage does make for the lowest possible monthly payment, it also means that the overall amount paid for the home will be significantly higher than it would be had the borrower chosen a 20- or 15-year term mortgage.</p>
                    
                    <p><strong>Fixed-Rate vs. Adjustable-Rate Mortgages</strong></p>
                    
                    <p>The biggest difference between these two types of home loans is that the interest rate on a fixed-rate loan stays the same while the interest rate on an adjustable-rate mortgage (ARM) is variable. In most cases, the initial interest rate offered on an adjustable-rate mortgage tends to be considerably lower than the market rate on a comparable fixed-rate loan, and this can be attractive to buyers. In fact, most ARMs guarantee the initial low rate for a designated period before the rate adjusts. This “fixed-rate” period can last anywhere from one month to ten years, depending on the terms of the loan agreement (shorter fixed-rate terms tend to carry lower interest rates). </p>
                    
                    <p>After the initial fixed-rate term, however, the interest rate on the loan will be based on current market rates and then adjusted every time the rate resets. If an ARM loan is held long enough, the interest rate will usually surpass the going rate for fixed-rate loans, resulting in higher and higher monthly payments.</p>
                    
                    <p><strong>Types of Fixed-Rate Mortgage Loans</strong></p>
                    
                    <p>Fixed-rate mortgages are available as conventional or unconventional loans. A conventional fixed-rate mortgage loan is one that meets Fannie Mae’s underwriting guidelines. This type of loan is essentially a deal between the borrower and the lender, so after calculating the loan’s interest and fees, the total cost paid by the borrower tends to be lower than with a comparable unconventional fixed-rate loan.</p>
                    
                    <p>An unconventional loan doesn’t adhere to Fannie Mae’s guidelines, but rather the guidelines of whatever program the borrower is using. Common types of unconventional fixed-rate loans are those available through government-insured programs like FHA, VA, and USDA loans. </p>
                    
                    <p>Government-backed home loans carry their own guidelines, which can in turn provide both pros and cons to the buyer. For instance, these types of loans can be purchased with lower to zero-money down payments, but they can have high prepayment penalties, and some, like FHA loans, require the borrower to pay private mortgage insurance (PMI) if they don’t put at least 20 percent down. </p>
                    
                    <p>Some unconventional fixed-rate loan programs even prevent the borrower from refinancing their loan to improve their interest rate. Thus, before selecting this or any other type of loan product, it is always recommended to review the program’s loan guidelines.</p>
                    
                    <p><strong>Is a Fixed-Rate Mortgage Right for You?</strong></p>
                    
                    <p>Before choosing between a fixed-rate mortgage and an ARM, you need to carefully consider a few key questions that include the following:</p>
                    
                    <ul>
                        <li>How much of a mortgage payment can you afford with your current financial situation?</li>
                        <li>How much can you afford to put down on the home?</li>
                        <li>How long do you plan to live in the home?</li>
                        <li>In what direction are the interest rates heading?</li>
                        <li>Will you still be able to afford an ARM if interest rates rise?</li>
                    </ul>
                    
                    <p>
                        Every home buyer is unique, to state the obvious. Only after answering these questions and taking a close look at your financial situation and other personal factors will you be able to tell if a fixed-rate conventional mortgage is the ideal choice for your home purchase.
                    </p>
                </div>
            </div>
        </>
    );
}

export default FixedRateMortgageLoans;
