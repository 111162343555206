import React from 'react';
import {useHistory, useLocation} from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import {useDispatch, useSelector} from "react-redux";
import {actionForm} from "../state/actions";
import AdvertisingDisclosures from './AdvertisingDisclosures';
import queryString from 'query-string'

const PropertyTypeHeader = () => {

    let history = useHistory();
    const { search } = useLocation();
    const state = useSelector(state => state);  
    const dispatch = useDispatch();
    const manualHandleChange = (name,val) => {
        dispatch(actionForm({
            [name]: val
        }))
    }

    const next = () => {
        dispatch(actionForm({
            step: state.step + 1
        }));
    }

    const back = () => {
        dispatch(actionForm({
            step: state.step - 1
        }));
    }

    function checkPub1330 () {

        if(state.publisher_id === "1330") {
            return true;
        }else{
            const pubId = queryString.parse(window.location.search).publisher_id;
            if(pubId === "1330") {
                localStorage.setItem('publisher_id', pubId);
                return true;
            }else {
                return false;
            }
        }
    }

    function submitStep (value) {
        manualHandleChange('homeType',value);
        next();
        history.push("/" + search);
        ScrollToTop();

    }  
    return (
        <>
            <div className="row">
                <div className="col-md-12 text-center">
                    <h5>UPDATE: {state.monthNames[new Date().getMonth()]} {new Date().getDate()}, {new Date().getFullYear()}</h5>
                    { checkPub1330() ? <h5>Call Us: <a style={{textDecoration: "none"}} href="tel:+18665051308">866-505-1308</a></h5> :  <h5>POTENTIALLY LOWER YOUR OVERALL MONTHLY PAYMENTS - CHECK IF YOU QUALIFY FOR REFINANCE OFFERS.*</h5>}
                    { checkPub1330() ? <h5>Take 60 Seconds to Calculate Your Savings!</h5> : <h5>Take 60 Second Quiz to Calculate Savings</h5>}
                </div>
            </div>
            <div className="row question m-0">
                <div className="col-md-2"/>
                <div className="col-md-8 text-center">
                    <p>Property Type</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-1"/>
                <div className="col-md-10">
                    <div className="buttons property-type">

                        <button className="single-family" type="button" onClick={() => submitStep('Single Family')}>
                            <span className="icon"/>
                            <span className="text">Single Family</span>
                        </button>

                        <button className="multi-family" type="button" onClick={() => submitStep('Multi-Family Dwelling Unit')}>
                            <span className="icon"/>
                            <span className="text">Multi Family</span>
                        </button>

                        <button className="town-home" type="button" onClick={() => submitStep('Condominium')}>
                            <span className="icon"/>
                            <span className="text">Town Home/Condo</span>
                        </button>

                        <button className="duplex-home" type="button" onClick={() => submitStep('Mobile Home')}>
                            <span className="icon"/>
                            <span className="text">Mobile Home</span>
                        </button>

                    </div>
                </div>
            </div>
        </>
    );
    
}

export default PropertyTypeHeader;