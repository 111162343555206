import React,{useEffect} from "react";

const VAMortgageLoanRefinance = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    return (
        <>
            <div className="row justify-content-center mt-3">
                <div className="col-md-8">
                    <h5>VA Mortgage Loan Refinance</h5>
                    
                    <p className="mt-3">The VA Home Loan program offers qualified active duty, veterans, and their spouses the ability to purchase a home with no money down and to obtain loans at competitive interest rates. It also offers existing VA loan holders two different refinance products to choose from – VA Streamline (IRRRL) Refinance, which allows homeowners to refinance their mortgages to lower their monthly mortgage payment; and VA Cash-Out Refinance, which allows homeowners to take cash out that can be used for a variety of needs, such as paying off debt or making home improvements.</p>
  
                    <p><strong>VA Streamline (IRRRL) Refinance</strong></p>
                    
                    <p>The VA’s Interest Rate Reduction Refinance Loan (IRRRL) is a “streamline” refinance option that allows current VA loan holders to refinance their mortgages into another VA loan with a lower interest rate. It is considered a streamline refinance because in most cases, there is little paperwork required, no income verification, no credit check, no property appraisal, and little-to-no out-of-pocket costs for the homeowner. With this type of refinance, the borrower can roll their closing costs into their overall loan amount.</p>
                    
                    <p>To be eligible for an IRRRL, a borrower must meet all the following requirements:</p>

                    <ul>
                        <li>Their current mortgage must be a VA-backed home loan</li>
                        <li>They are using the IRRRL to refinance their existing VA-backed home loan</li>
                        <li>They must currently live in (or used to live in) the home covered by the loan</li>
                    </ul>

                    <p>If there is already a second mortgage on the home, then the holder of the second mortgage must agree to make the new VA-backed loan the first mortgage.</p>

                    <p><strong>VA Cash-Out Refinance</strong></p>
                    
                    <p>Unlike the IRRRL refinance, the VA’s Cash-Out Refinance allows any qualified veteran homeowner the ability to tap into their home’s equity, even if their current home loan isn’t a VA loan. This means that if their loan is a FHA, USDA, or conventional loan, they can still qualify for a VA Cash-Out refinance if they are a veteran.</p>
                    
                    <p>With this type of refinance, the lender will usually require a minimum credit score and a VA appraisal. The home also needs to be the borrower’s primary residence. Unlike traditional cash-out refinances, which require at least 20% equity to remain in the home to avoid paying mortgage insurance, a VA Cash-Out refinance allows a borrower to finance up to 100% of the appraised value of their home, though the exact amount available to the borrow can vary per lender. Unlike conventional and FHA loans, VA refinance loans don't require mortgage insurance even when 100% financed.</p>
                    
                    <p>If you are applying for a VA Cash-Out refinance, then it is important to know that all closing costs must be paid upfront. Unlike with the IRRRL, closing costs cannot be rolled into this loan. However, if necessary, some of the cash taken out from the refinance can be used to pay the closing costs.</p>

                    <p><strong>Are There Fees Associated with VA Mortgage Loan Refinances?</strong></p>

                    <p>Regardless of which VA refinance product you choose, there will be fees associated with it. These are typically the same fees associated with a traditional mortgage refinance, but there is one fee that is unique to VA refinances — the VA funding fee.</p>

                    <p>On the VA’s Interest Rate Reduction Refinance Loan, the funding fee is 0.5% of the loan amount. On a VA cash-out refinance, the funding fee is 2.3% of the total loan if you are refinancing your first VA loan. If it is not your first refinance, then the funding fee is 3.6%.</p>

                    <p>In special circumstances, the VA funding fee may be waived. Traditionally, this benefit is restricted to those that have suffered a service-related disability, the surviving spouses of service members who died in the line of duty or from a service-related injury, and active-duty service members who have received a Purple Heart.</p>

                    <p><strong>VA Mortgage Refinance Eligibility</strong></p>

                    <p>To be eligible for a VA refinance, you must meet the same eligibility requirements for a home purchase VA Loan. Borrowers must either be an active-duty service member, an honorably discharged veteran, or the spouse of a current service member or veteran. Veterans must have served on active duty for more than 90 consecutive days during wartime or more than 181 days of service during peacetime and have been honorably discharged. If the borrower is a member of the National Guard or a Reservist, then they must have served at least six years.</p>

                    <p>If the borrower is the widow or widower of a veteran, then they must be unmarried at the time of the refinance, and their spouse had to have died in the line of duty or from a service-related injury, unless applying for an IRRRL. In that case, the cause of death does not matter, but the VA loan must have been purchased prior to the spouse’s death.</p>

                    <p>Other requirements can include:</p>

                    <ul>
                        <li>Minimum credit score ranging from 580 to 640 (dependent upon the lender)</li>
                        <li>Debt-to-income ratio of 41% or lower, although some lenders may accept DTIs as high as 56% if certain credit and income thresholds are met</li>
                        <li>Individual lenders may have other requirements unique to them</li>
                    </ul>
                    
                    <p><strong>Is There a Loan Limit on VA Refinances?</strong></p>

                    <p>The VA does not place limits on their loan amounts, but it does have limits on how much of the loan it will guarantee. Currently, eligible veterans, service members, and survivors with full VA loan entitlement no longer have limits on loans over $144,000. This means borrowers do not have to pay a down payment, and the VA guarantees the lender that if the borrower defaults on a loan that’s over $144,000, the VA will pay them up to 25% of the loan amount.</p>
                    
                    <p>If the borrower has remaining entitlement, then their VA home loan limit will be based on the county loan limit where they reside. In this case, a down payment will usually be required if the borrower is using their remaining entitlement and their loan amount is over $144,000. Most lenders require that the borrower’s entitlement, their down payment, or a combination of both cover at least 25% of their total loan amount.</p>
                    
                    <p>To learn more about the VA’s refinance programs, visit the <a href="https://www.va.gov/housing-assistance/home-loans/loan-types/interest-rate-reduction-loan/" target="_blank">U.S. Department of Veterans Affairs website</a>.</p>

                </div>
            </div>
        </> 
    );
}

export default VAMortgageLoanRefinance;
