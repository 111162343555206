import React,{useEffect} from "react";

const ConventionalMortgageLoans = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    return (
        <>
            <div className="row justify-content-center mt-3">
                <div className="col-md-8">
                    <h5>Conventional Mortgage Loans</h5>
                    
                    <p className="mt-3">Conventional mortgage loans are loans that are not federally guaranteed. These loans conform to the limits set by the Federal Housing Finance Administration (FHFA) and follow the Fannie Mae and Freddie Mac requirement guidelines. This means they can be more challenging to get approved for, but they do not carry the limitations commonly found in government-backed loans. As a result, conventional mortgage loans are more accommodating to a wider set of buyers and properties.</p>
                    
                    <p>In this guide, you will learn everything you need to know about conventional mortgages, so you can make a more informed decision when it comes time for you to choose a mortgage product for your home purchase.</p>
                    
                    <p><strong>Minimum Credit Score Requirements for a Conventional Home Loan</strong></p>
                    
                    <p>Most lenders that offer conventional home loans look for borrowers to have at least a credit score of 640, but it is not uncommon for applicants with scores as low as 620 to qualify. If you want to secure the lowest interest rate on your loan, however, you will want to have a score of 740 or above. If you are closer to the minimum credit score requirement, then putting more money down on the house may help you get a lower rate on your loan.</p>
                    
                    <p><strong>Down Payment Requirements for a Conventional Mortgage</strong></p>
                    
                    <p>Depending on certain factors, such as the borrower’s credit history, personal situation, and type of property being purchased, a first-time home buyer may be able to get a conventional mortgage with a down payment as low as 3%. The minimum down payment requirement increases to 5% for buyers who are not first-time buyers and those who make less than 80% of the median income in the area.</p>
                    
                    <p>Additional down payment requirements for other types of buying scenarios include: </p>
                    
                    <ul>
                        <li>15% down payment if the home is not a single-family home </li>
                        <li>10% down payment if the property being purchased is a second home</li>
                        <li>5% down payment if the conventional loan has an adjustable rate</li>
                        <li>20% to 40% down payment if the loan is a jumbo loan</li>
                    </ul>
                    
                    <p>If you want to refinance your existing conventional mortgage, then you will need to have at least 5% equity in your home to qualify. If you want to do a cash-out refinance, then you will need at least 20% equity in your home. </p>
                    
                    <p><strong>Conventional Mortgages and Private Mortgage Insurance</strong></p>
                    
                    <p>Although a conventional mortgage can be acquired with as little as 3% down for first-time buyers, it should be noted that any borrower who does not put at least 20% down on their home will be required to pay private mortgage insurance (PMI) every month in their mortgage payment. This is to protect the lender in the event the borrower defaults on the loan. </p>
                    
                    <p>How much you will have to pay for PMI is determined on a range of variables, including the loan type, your credit score, and the size of your down payment. The good thing is that once you reach 20% equity in your home, you can ask your lender to remove the PMI from your mortgage payment. If you do not request your lender to remove the PMI once you hit 20% equity, the lender will automatically remove it once your home reaches 22% equity.</p>
                    
                    <p><strong>Debt-to-Income Requirements for Conventional Mortgages</strong></p>
                    
                    <p>A borrower’s debt-to-income ratio (DTI) is one of the most important factors lenders take into consideration when processing conventional loan applications. DTI is a percentage that represents how much of the borrower’s monthly income is used to pay off their existing debts. </p>
                    
                    <p>To qualify for a conventional mortgage loan, you want your DTI to be 43% or lower, but ideally it should be 36% or lower. You may be able to qualify with a DTI as high as 50%, but to do so you will need to meet higher credit and down payment requirements. </p>
                    
                    <p>To determine your DTI, add up the minimum monthly payments on all your debts and divide that total by your gross monthly income. </p>
                    
                    <p><strong>Documentation Requirements for Conventional Mortgages</strong></p>
                    
                    <p>Lenders do not want borrowers of conventional loans to carry mortgage payments that exceed 28% of their gross income, so they require a wealth of documentation during the application process to ensure a borrower can meet that requirement. Among the required documents are:</p>
                    
                    <ul>
                        <li>Proof of Income</li>
                        <ul>
                            <li>Last thirty days of pay stubs showing income as well as year-to-date income</li>
                            <li>Two years of federal tax returns</li>
                            <li>Sixty days or a quarterly statement of all asset accounts, including your checking, savings, and any investment accounts</li>
                            <li>Two years of W-2 statements</li>
                            <li>If additional income is being included, such as alimony, veteran benefits, or other, the appropriate documentation must be included to support the income claim</li>
                        </ul>
                        <li>Assets</li>
                        <ul>
                            <li>Proof of funds for the down payment and closing costs </li>
                            <li>Cash reserve statement</li>
                            <li>Investment accounts statements</li>
                            <li>Notarized gift letters if the money being put down is being gifted from friends or relatives</li>
                        </ul>
                        <li>Employment Verification</li>
                        <li>Additional Documents</li>
                        <ul>
                            <li>Driver’s license or state ID card</li>
                            <li>Social security number</li>
                            <li>Signed authorization for credit report check</li>
                        </ul>
                    </ul>
                    
                    <p><strong>Conventional Home Loan Limits</strong></p>
                    
                    <p>To qualify for a conforming conventional home loan, the amount you are borrowing must fall within the loan limits set by Fannie Mae and Freddie Mac. For homes purchased in 2021, the limit is capped at $548,250, but there are exceptions if the home being purchased is in a high-cost area, like Alaska or Hawaii. The 2021 limit for home purchases in high-cost areas is $822,375. Conventional loan limits change annually, so for the most up-to-date information, visit the <a href="https://www.fhfa.gov/DataTools/Tools/Pages/Conforming-Loan-Limits-Map.aspx">Federal Housing Finance Agency website</a>.</p>
                    
                    <p>Not all conventional loans are conforming, however. For instance, jumbo loans, while still conventional loans, are considered non-conforming because they do not adhere to the loan limits put in place by Fannie Mae and Freddie Mac. </p>
                    
                    <p><strong>Who May Not Qualify for a Conventional Mortgage?</strong></p>
                    
                    <p>Conventional mortgages, while popular, aren’t for everybody. For instance, if you are a young adult with no established credit history or someone with a little more debt than normal or who has filed for bankruptcy or foreclosure within the past seven years, then you may have difficulty getting approved for a conventional loan. Others who may find it tough to qualify for this type of mortgage include those with:</p>
                    
                    <ul>
                        <li>Credit scores below 640</li>
                        <li>DTIs above 43%</li>
                        <li>Less than 10% to 20% of the home's purchase price for a down payment</li>
                    </ul>
                    
                    <p>If you fall under one of these categories and you wind up getting turned down for a conventional mortgage, all is not lost. The first thing you should do is ask the lender to provide you with their reasons for denying you in writing. This will help you determine what factors you need to address. </p>
                    
                    <p>You may also still qualify for other programs that might be better suited to help someone in your position purchase a home. For example, if you are a veteran of the Armed Forces, you may want to try for a VA loan, which can often be obtained with no money down and no PMI. Or you may qualify for an FHA loan. Today, there are a variety of home loan types designed to help buyers achieve the American dream of owning a home.</p>
                </div>
            </div>
        </>
    );
}

export default ConventionalMortgageLoans;
