import React from "react";
import ScrollToTop from "../Components/ScrollToTop";
import Footer from "../Components/Footer";
import ReactGA from 'react-ga4';

export default class ActiveMilitary extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.runeHawkTalon();

        ReactGA.initialize(process.env.REACT_APP_GA);
        ReactGA.send({ hitType: 'pageview', page: '/activemilitary'});

        ReactGA.event({
            category: 'Step 8, Military status',
            action: 'Step 8, Military status',
            label: 'Step 8, Military status'
        });
    }

    submitStep = (value) =>{
        this.props.manualHandleChange('activeMilitary',value)

        this.props.next();

        ScrollToTop();
    }

    render () {
        return (
            <>
                <form>
                    <input type="hidden" name="universal_leadid" value="" id="leadid_token"/>
            <div className="section-question">
                <div className="row question">
                    <div className="col-md-2"/>
                    <div className="col-md-8 text-center">
                        {this.props.values.publisher_id === "1330" ? <h5>Call Us: <a style={{textDecoration: "none"}} href="tel:+18665051308">866-505-1308</a></h5>: ""}
                        <p>Are you or your spouse active or veteran military?</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-1"/>
                    <div className="col-md-10">
                        <div className="buttons military">
                            <button type="button" className="yes" onClick={() => this.submitStep('Y')}>
                                <span className="icon"/>
                                <span className="text">Yes</span>
                            </button>

                            <button type="button" className="no" onClick={() => this.submitStep('N')}>
                                <span className="icon"/>
                                <span className="text">No</span>
                            </button>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4"/>
                    <div className="col-md-4 text-center mt-2 mb-2">
                        <a className="text-black-50 pointer-cursor" style={{paddingRight:20}} onClick={this.props.back}>Back</a>
                    </div>
                </div>
            </div>
                </form>
                <Footer/>
                <div className="m-5 text-center" style={{fontSize:13}}>
                    <div className="mt-2">© <span>{new Date().getFullYear()}</span> FedRateOptions.com</div>
                </div>
                </>
        )
    }
}
