import React,{useEffect} from "react";

const HomeEquityExplained = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    return (
        <>
            <div className="row justify-content-center mt-3">
                <div className="col-md-8">
                    <h5>Home Equity Explained</h5>
                    <p className="mt-3">Owning a home has long been considered part of the American Dream because it is one of the predominate ways that most families build wealth. Buying a home is one of the few financial investments a family can make that is almost guaranteed to deliver positive returns, because with every mortgage payment that is made, equity is increased.</p>
                    <p>So what exactly is home equity?</p>
                    <p>Home equity is the difference between a home’s current market value and the amount left that is owed on the mortgage loan. If the number is positive, then you have equity in your home. If it is negative, however, then you owe more than your home is worth, and this is what is known as being "underwater."</p>
                    <p><strong>How to Increase Your Home Equity</strong></p>
                    <p>There are typically four ways you can increase the amount of equity you have in your home:</p>
                    <ul>
                        <li>Put a larger amount of money down on the home when you purchase it</li>
                        <li>Pay your monthly mortgage payments on time</li>
                        <li>Make an additional payment every month to reduce the principal on your loan (if applicable with your loan type)</li>
                        <li>Complete home improvements to increase your home’s market value</li>
                    </ul>
                    <p>Your home equity can also rise should property values increase in your area.</p>
                    <p><strong>How Home Equity Works</strong></p>
                    <p>Building home equity is a long game. It doesn’t happen overnight. The reason it takes so long to acquire is because for the first half of your mortgage, most of your monthly payment goes toward interest with only a little being applied to the principal. At the around the half-way mark, things shift and more and more of your payment starts being applied to the principal of your loan.</p>
                    <p>If your loan terms permit and you can financially afford it, making an additional payment to the lender every month will help reduce your principal faster because once the interest has been paid, the total amount of your second payment will be applied to the principal. This will not only help you accumulate equity faster, but will also help you pay off your home sooner.</p>
                    <p><strong>How to Determine Home Equity</strong></p>
                    <p>When you receive your mortgage statement every month, it tells you how much you still owe on your home loan. The next thing you need to find out is what your home is currently worth on the market. There are dozens of home valuation tools available to choose from online, but these will only give you a rough estimate of your home’s market value. Using one of these resources can give you an idea of your equity, but to get an accurate number, you should get your home appraised.</p>
                    <p>Because home appraisals are not free, most homeowners only get them if they are preparing to sell or planning to apply for a home equity loan.</p>
                    <p><strong>What Classifies a Home as Equity-Rich?</strong></p>
                    <p>When a home is said to be "equity-rich," it means that the amount owed on the home loan has dropped to below 50% of the home’s market value. According to the fourth-quarter 2020 U.S. Home Equity & Underwater Report from <a target="_blank" href="https://www.attomdata.com/news/market-trends/home-sales-prices/attom-data-solutions-q4-2020-u-s-home-equity-underwater-report/" className="text-primary">ATTOM Data Solutions</a>, 17.8 million residential properties in the United States were considered equity-rich, which equates to 30.2 percent of all mortgaged homes in the country.</p>
                    <p><strong>How, Why, and When to Use Home Equity</strong></p>
                    <p>Home equity can play a valuable role in a household’s financial health because it can be tapped into in events where a surplus of money is needed. Some of the ways homeowners use the equity in their homes is to help cover costs associated with:</p>
                    <ul>
                        <li>Buying a new or second home</li>
                        <li>Funding retirement</li>
                        <li>Paying off other high-interest debts</li>
                        <li>Paying for college</li>
                        <li>Starting a business</li>
                        <li>Making long-term investments</li>
                        <li>Home improvements</li>
                        <li>Emergency expenses</li>
                        <li>Medical bills</li>
                    </ul>
                    <p>There are three options available for accessing home equity – a home equity loan, a home equity line of credit (HELOC), or a cash-out refinance.</p>
                    <p><strong>Home Equity Loan:</strong> Home equity loans come with fixed interest rates, so your payments will be the same every month. With this type of product, you get a lump sum of cash which you will start paying back immediately. Once the loan is paid in full, you will need to apply for another loan if you want to access your equity for another purpose.</p>
                    <p><strong>HELOC:</strong> Home equity lines of credit have variable-rates and work like a credit card in that you can continue to draw from it as you pay it down if needed. Unlike a credit card, however, most HELOCs are made available with draw periods for a predetermined length of time, typically ten years. After the draw period expires, you must continue repaying whatever you borrowed over the next 10 to 20 years or until the balance reaches zero.</p>
                    <p><strong>Cash-Out Refinance:</strong> When you do a cash-out refinance, you are essentially creating a new, larger mortgage on your home. With transaction, you use the money from the new mortgage to pay off the old one and whatever is left over is paid out to you in cash.</p>
                    <p>Building equity in your home can provide you with a convenient, low-cost way of accessing a large sum of money to cover some of life’s more expensive and/or unexpected expenses. But before you access the equity you have worked so hard to build, first determine if the need is worth it. If it is, then compare the loan types available through your lender to ensure you get the best product for your needs.</p>
                </div>
            </div>
        </>
    );
}

export default HomeEquityExplained;
