import React,{useEffect} from "react";
import axios from "axios";
import queryString from 'query-string'
import Header from "./../Components/Header";
import PropertyType from "./../Formsteps/PropertyType";
import PropertyValue from "./../Formsteps/PropertyValue";
import MortgageBalance from "./../Formsteps/MortgageBalance";
import CreditScore from "./../Formsteps/CreditScore";
import CashOut from "./../Formsteps/CashOut";
import Employment from "./../Formsteps/Employment";
import FHA from "./../Formsteps/FHA";
import ActiveMilitary from "./../Formsteps/ActiveMilitary";
import Address from "./../Formsteps/Address";
import Name from "./../Formsteps/Name";
import Email from "./../Formsteps/Email";
import Phone from "./../Formsteps/Phone";
import Final from "./../Formsteps/Final";
import { Helmet } from 'react-helmet';
import {useDispatch, useSelector} from "react-redux";
import {actionForm} from "../state/actions";

function Form () {
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!state.publisher_id) {
            const getParameters = queryString.parse(window.location.search);
            let publisher_id, rcid, subid;

            if (getParameters.publisher_id) {
                publisher_id = getParameters.publisher_id
                localStorage.setItem('publisher_id',publisher_id)
            } else if (localStorage.getItem('publisher_id')) {
                publisher_id = localStorage.getItem('publisher_id')
            } else {
                publisher_id = 632;
            }

            if (getParameters.rcid) {
                rcid = getParameters.rcid
                localStorage.setItem('rcid',rcid)
            }else if (localStorage.getItem('rcid')) {
                rcid = localStorage.getItem('rcid')
            }else {
                rcid = "";
            }

            if (getParameters.subid) {
                subid = getParameters.subid
                localStorage.setItem('subid',subid)
            }else if (localStorage.getItem('subid')) {
                subid = localStorage.getItem('subid')
            }else {
                subid = "";
            }

            manualHandleChange('publisher_id', publisher_id);
            manualHandleChange('rcid', rcid);
            manualHandleChange('subid', subid);

            axios.get(process.env.REACT_APP_API_URL + '/api/user/ip_address/').then(res => {
                manualHandleChange('ipaddress', res.data.ip_address);
            });

            axios.get(process.env.REACT_APP_API_URL + '/api/hdi/', {
                params: {
                    upload_type: state.upload_type,
                    publisher_id: publisher_id
                }
            }).then(res => {
                manualHandleChange('hid', res.data.hid);
            });
        }
    },[]);

    const manualHandleChange = (name,val) => {
        dispatch(actionForm({
            [name]: val
        }))
        removeError(name);
    }

    const next = () => {
        dispatch(actionForm({
            step: state.step + 1
        }));
    }

    const back = () => {
        dispatch(actionForm({
            step: state.step - 1
        }));
    }

    const openMainPage = () => {
        dispatch(actionForm({
            step: 1
        }));
        window.scrollTo(0, 0);
    }

    const handleChange = input => e => {
        dispatch(actionForm({
            [input]: e.target.value
        }))
        removeError(input);
    }

    const handleSubmit = () =>{
        dispatch(actionForm({
            signupSuccess: true
        }))
        next()
    }

    const setError = (name) => {
        if (!isError(name)) {
            let errorList = state.incorrectFields;
            errorList.push(name)
            dispatch(actionForm({
                incorrectFields: errorList
            }))
        }
    }

    const removeError = (name) => {
        if (isError(name)) {
            let errorList = state.incorrectFields;
            errorList = errorList.filter(item => item !== name);
            dispatch(actionForm({
                incorrectFields: errorList
            }))
        }
    }

    const isError = (name) => {
        return (state.incorrectFields.indexOf(name) >= 0);
    }

    const getStep = () => {
        return state.step
    }

    const renderStep = (step) => {
        const values = state;

        switch (step) {
            case 1:
                return (
                    <PropertyType
                        values = {values}
                        handleChange = {handleChange}
                        next = {next}
                        back = {back}
                        manualHandleChange = {manualHandleChange}
                        setError = {setError}
                        removeError = {removeError}
                        isError = {isError}
                        openMainPage = {openMainPage}
                    />
                )
            case 2:
                return (
                    <PropertyValue
                        values = {values}
                        handleChange = {handleChange}
                        next = {next}
                        back = {back}
                        manualHandleChange = {manualHandleChange}
                        setError = {setError}
                        removeError = {removeError}
                        isError = {isError}
                        openMainPage = {openMainPage}
                    />
                )
            case 3:
                return (
                    <MortgageBalance
                        values = {values}
                        handleChange = {handleChange}
                        next = {next}
                        back = {back}
                        manualHandleChange = {manualHandleChange}
                        setError = {setError}
                        removeError = {removeError}
                        isError = {isError}
                        openMainPage = {openMainPage}
                    />
                )
            case 4:
                return (
                    <CreditScore
                        values = {values}
                        handleChange = {handleChange}
                        next = {next}
                        back = {back}
                        manualHandleChange = {manualHandleChange}
                        setError = {setError}
                        removeError = {removeError}
                        isError = {isError}
                        openMainPage = {openMainPage}
                    />
                )
            case 5:
                return (
                    <CashOut
                        values = {values}
                        handleChange = {handleChange}
                        next = {next}
                        back = {back}
                        manualHandleChange = {manualHandleChange}
                        setError = {setError}
                        removeError = {removeError}
                        isError = {isError}
                        openMainPage = {openMainPage}
                    />
                )
            case 6:
                return (
                    <Employment
                        values = {values}
                        handleChange = {handleChange}
                        next = {next}
                        back = {back}
                        manualHandleChange = {manualHandleChange}
                        setError = {setError}
                        removeError = {removeError}
                        isError = {isError}
                        openMainPage = {openMainPage}
                    />
                )
            case 7:
                return (
                    <FHA
                        values = {values}
                        handleChange = {handleChange}
                        next = {next}
                        back = {back}
                        manualHandleChange = {manualHandleChange}
                        setError = {setError}
                        removeError = {removeError}
                        isError = {isError}
                        openMainPage = {openMainPage}
                    />
                )
            case 8:
                return (
                    <ActiveMilitary
                        values = {values}
                        handleChange = {handleChange}
                        next = {next}
                        back = {back}
                        manualHandleChange = {manualHandleChange}
                        setError = {setError}
                        removeError = {removeError}
                        isError = {isError}
                        openMainPage = {openMainPage}
                    />
                )
            case 9:
                return (
                    <Address
                        values = {values}
                        handleChange = {handleChange}
                        next = {next}
                        back = {back}
                        manualHandleChange = {manualHandleChange}
                        setError = {setError}
                        removeError = {removeError}
                        isError = {isError}
                        openMainPage = {openMainPage}
                    />
                )
            case 10:
                return (
                    <Name
                        values = {values}
                        handleChange = {handleChange}
                        next = {next}
                        back = {back}
                        manualHandleChange = {manualHandleChange}
                        setError = {setError}
                        removeError = {removeError}
                        isError = {isError}
                        openMainPage = {openMainPage}
                    />
                )
            case 11:
                return (
                    <Email
                        values = {values}
                        handleChange = {handleChange}
                        next = {next}
                        back = {back}
                        manualHandleChange = {manualHandleChange}
                        setError = {setError}
                        removeError = {removeError}
                        isError = {isError}
                        openMainPage = {openMainPage}
                    />
                )
            case 12:
                return (
                    <Phone
                        values = {values}
                        handleChange = {handleChange}
                        next = {next}
                        back = {back}
                        manualHandleChange = {manualHandleChange}
                        setError = {setError}
                        removeError = {removeError}
                        isError = {isError}
                        openMainPage = {openMainPage}
                    />
                )
            case 13:
                return (
                    <Final
                        values = {values}
                        handleChange = {handleChange}
                        next = {next}
                        handleSubmit = {handleSubmit}
                        openMainPage = {openMainPage}
                    />
                )
        }
    }

    return (
        <div>
            {
                state.publisher_id == 1330 &&
                <Helmet>
                    
                    <script>
                        {`
                            
                        `}
                    </script>
                </Helmet>
            }
            {state.step &&
            <>
                <Header step={getStep}/>
                {renderStep(state.step)}
            </>
            }
        </div>
    );
}

export default Form;
