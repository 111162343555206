import React from 'react';
import {useHistory} from "react-router-dom";

const Header = () => {
    let history = useHistory();

    return (
        <section id="header">
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12 text-center">
                            <div id="logo" className="text-center" style={{cursor:"pointer"}}><a onClick={()=>{history.push("/")}}/></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3"/>
                        <div className="col-md-6">
                            <div className="progress" style={{height:"3px"}}>
                                <div id="sectionProgress" className="progress-bar bg-warning" role="progressbar" style={{width:"0%"}} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"/>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </section>
    );
}

export default Header;
