import React from 'react';
import {Link,useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import queryString from 'query-string'

const Articles = () => {
    const state = useSelector(state => state);
    const { search } = useLocation();

    function checkPub1330 () {

        if(state.publisher_id === "1330") {
            return true;
        }else{
            const pubId = queryString.parse(window.location.search).publisher_id;
            if(pubId === "1330") {
                localStorage.setItem('publisher_id', pubId);
                return true;
            }else {
                return false;
            }
        }
    }

    return (
        <>
            {checkPub1330() &&
                <div className="row justify-content-center mt-5 mb-5 ml-1 mr-1">
                    <div className="col-md-8 col-sm-12">
                        <div className="row justify-content-center">
                            <div className="col-md-auto col-sm-12 m-1">
                                <Link className={"pointer-cursor text-black-50"} to={`/income-requirements${search}`}>What are the Income Requirements to Qualify for a Conventional Mortgage?</Link>
                            </div>
                            <div className="col-md-auto col-sm-12 m-1">
                                <Link className={"pointer-cursor text-black-50"} to={`/what-are-the-other-costs-connected-with-buying-a-house${search}`}>What are the Other Costs Connected with Buying a House?</Link>
                            </div>
                            <div className="col-md-auto col-sm-12 m-1">
                                <Link className={"pointer-cursor text-black-50"} to={`/home-equity-explained${search}`}>Home Equity Explained</Link>
                            </div>
                            <div className="col-md-auto col-sm-12 m-1">
                                <Link className={"pointer-cursor text-black-50"} to={`/fixed-rate-mortgage-loans${search}`}>Fixed-Rate Mortgage Loans</Link>
                            </div>
                            <div className="col-md-auto col-sm-12 m-1">
                                <Link className={"pointer-cursor text-black-50"} to={`/fha-streamline${search}`}>FHA Streamline</Link>
                            </div>
                            <div className="col-md-auto col-sm-12 m-1">
                                <Link className={"pointer-cursor text-black-50"} to={`/conventional-mortgage-loans${search}`}>Conventional Mortgage Loans</Link>
                            </div>
                            <div className="col-md-auto col-sm-12 m-1">
                                <Link className={"pointer-cursor text-black-50"} to={`/how-much-income-do-i-need-to-buy-a-house${search}`}>How Much Income Do I Need to Buy a House?</Link>
                            </div>
                            <div className="col-md-auto col-sm-12 m-1">
                                <Link className={"pointer-cursor text-black-50"} to={`/employed-self-employed-income-requirements-for-buying-a-home${search}`}>Employed vs. Self-Employed Income Requirements for Buying a Home</Link>
                            </div>
                            <div className="col-md-auto col-sm-12 m-1">
                                <Link className={"pointer-cursor text-black-50"} to={`/loan-to-value-ltv-explained${search}`}>Loan-to-Value (LTV) Explained</Link>
                            </div>
                            <div className="col-md-auto col-sm-12 m-1">
                                <Link className={"pointer-cursor text-black-50"} to={`/debt-consolidation-mortgage-refinance${search}`}>Debt Consolidation Mortgage Refinance</Link>
                            </div>
                            <div className="col-md-auto col-sm-12 m-1">
                                <Link className={"pointer-cursor text-black-50"} to={`/fha-refinance${search}`}>FHA Refinance</Link>
                            </div>
                            <div className="col-md-auto col-sm-12 m-1">
                                <Link className={"pointer-cursor text-black-50"} to={`/mortgage-refinance${search}`}>Mortgage Refinance</Link>
                            </div>
                            <div className="col-md-auto col-sm-12 m-1">
                                <Link className={"pointer-cursor text-black-50"} to={`/mortgage-refinance-to-lower-payment${search}`}>Mortgage Refinance to Lower Payment</Link>
                            </div>
                            <div className="col-md-auto col-sm-12 m-1">
                                <Link className={"pointer-cursor text-black-50"} to={`/va-mortgage-loan-refinance${search}`}>VA Mortgage Loan Refinance</Link>
                            </div>

                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default Articles;
