import React from "react";
import Footer from "../Components/Footer";
import ReactGA from 'react-ga4';

export default class Name extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.runeHawkTalon();

        ReactGA.initialize(process.env.REACT_APP_GA);
        ReactGA.send({ hitType: 'pageview', page: '/name'});

        ReactGA.event({
            category: 'Step 10, Name',
            action: 'Step 10, Name',
            label: 'Step 10, Name'
        });
    }

    submitStep = () =>{
        let isError = false;

        if (!this.props.values.firstName.length) {
            this.props.setError('firstName');
            isError = true;
        }

        if (!this.props.values.lastName.length) {
            this.props.setError('lastName');
            isError = true;
        }

        if (!isError) {
            this.props.next();
        }
    }

    handleChange = (e) => {
        if (e.currentTarget.value.match(/^[A-Za-z]+$/) || e.currentTarget.value == "") {
            this.props.manualHandleChange(e.currentTarget.dataset.type, e.currentTarget.value);
        }
        
    }

    render () {
        return (
            <>
                <form onSubmit={e => { e.preventDefault(); }}>
                    <input type="hidden" name="universal_leadid" value="" id="leadid_token"/>
            <div className="section-question">
                <div className="row question">
                    <div className="col-md-2"/>
                    <div className="col-md-8 text-center">
                        {this.props.values.publisher_id === "1330" ? <h5>Call Us: <a style={{textDecoration: "none"}} href="tel:+18665051308">866-505-1308</a></h5>: ""}
                        <p>What's your name?</p>
                        <small>Personal Information is Safe & Secure.</small>
                    </div>
                </div>

                <div className="form-group row mb-4">
                    <div className="col-md-4"/>
                    <div className="col-md-4 input-holder">
                        <input
                            type="text"
                            className={`form-control border-input ${!this.props.isError('firstName') ? '' : 'invalid'}`}
                            placeholder="First Name"
                            value={this.props.values.firstName}
                            data-type="firstName"
                            onChange={this.handleChange}
                        />
                        {this.props.isError('firstName') &&
                        <div className="invalid-feedback d-block">
                            Please enter your first name.
                        </div>
                        }
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-md-4"/>
                    <div className="col-md-4 input-holder">
                        <input
                            type="text"
                            className={`form-control border-input ${!this.props.isError('lastName') ? '' : 'invalid'}`}
                            placeholder="Last Name"
                            value={this.props.values.lastName}
                            data-type="lastName"
                            onChange={this.handleChange}
                        />
                        {this.props.isError('lastName') &&
                        <div className="invalid-feedback d-block">
                            Please enter your last name.
                        </div>
                        }
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4"/>
                    <div className="col-md-4 text-center mt-5 mb-5">
                        <a className="text-black-50 pointer-cursor" style={{paddingRight:20}} onClick={this.props.back}>Back</a>
                        <button type="button" onClick={this.submitStep} className="btn-block btn btn-success btn-lg btn-text-white btn-350">Next >
                        </button>
                    </div>
                </div>
            </div>
                </form>
                <Footer/>
                <div className="m-5 text-center" style={{fontSize:13}}>
                    <div className="mt-2">© <span>{new Date().getFullYear()}</span> FedRateOptions.com</div>
                </div>
                </>
        )
    }
}
