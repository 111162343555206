import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Form from "./pages/Form";
import IncomeRequirements from "./pages/articles/IncomeRequirements";
import Layout from "./Components/Layout";
import HomeEquityExplained from "./pages/articles/HomeEquityExplained";
import BuyingHouseCost from "./pages/articles/BuyingHouseCost";
import store from './state/store'
import { Provider } from 'react-redux'
import FixedRateMortgageLoans from "./pages/articles/FixedRateMortgageLoans";
import FHAStreamline from "./pages/articles/FHAStreamline";
import ConventionalMortgageLoans from "./pages/articles/ConventionalMortgageLoans";
import HowMuchIncomeDoINeedtoBuyaHouse from "./pages/articles/HowMuchIncomeDoINeedtoBuyaHouse";
import EmployedSelfEmployedIncome from "./pages/articles/EmployedSelfEmployedIncome";
import LoanToValue from "./pages/articles/LoanToValue";
import DebtConsolidationMortgageRefinance from "./pages/articles/DebtConsolidationMortgageRefinance";
import FHARefinance from "./pages/articles/FHARefinance";
import MortgageRefinance from "./pages/articles/MortgageRefinance";
import MortgageRefinanceToLowerPayment from "./pages/articles/MortgageRefinanceToLowerPayment";
import VAMortgageLoanRefinance from "./pages/articles/VAMortgageLoanRefinance";

export default function App() {
    return (
        <Provider store={store}>
        <Router>
            <Switch>
                <Route path="/income-requirements">
                    <Layout>
                        <IncomeRequirements />
                    </Layout>
                </Route>
                <Route path="/home-equity-explained">
                    <Layout>
                        <HomeEquityExplained />
                    </Layout>
                </Route>
                <Route path="/what-are-the-other-costs-connected-with-buying-a-house">
                    <Layout>
                        <BuyingHouseCost />
                    </Layout>
                </Route>
                <Route path="/fixed-rate-mortgage-loans">
                    <Layout>
                        <FixedRateMortgageLoans />
                    </Layout>
                </Route>
                <Route path="/fha-streamline">
                    <Layout>
                        <FHAStreamline />
                    </Layout>
                </Route>
                <Route path="/conventional-mortgage-loans">
                    <Layout>
                        <ConventionalMortgageLoans />
                    </Layout>
                </Route>
                <Route path="/how-much-income-do-i-need-to-buy-a-house">
                    <Layout>
                        <HowMuchIncomeDoINeedtoBuyaHouse />
                    </Layout>
                </Route>
                <Route path="/employed-self-employed-income-requirements-for-buying-a-home">
                    <Layout>
                        <EmployedSelfEmployedIncome />
                    </Layout>
                </Route>
                <Route path="/loan-to-value-ltv-explained">
                    <Layout>
                        <LoanToValue />
                    </Layout>
                </Route>
                <Route path="/debt-consolidation-mortgage-refinance">
                    <Layout>
                        <DebtConsolidationMortgageRefinance />
                    </Layout>
                </Route>
                <Route path="/fha-refinance">
                    <Layout>
                        <FHARefinance />
                    </Layout>
                </Route>
                <Route path="/mortgage-refinance">
                    <Layout>
                        <MortgageRefinance />
                    </Layout>
                </Route>
                <Route path="/mortgage-refinance-to-lower-payment">
                    <Layout>
                        <MortgageRefinanceToLowerPayment />
                    </Layout>
                </Route>
                <Route path="/va-mortgage-loan-refinance">
                    <Layout>
                        <VAMortgageLoanRefinance />
                    </Layout>
                </Route>
                <Route path="/">
                    <Form />
                </Route>
            </Switch>
        </Router>
        </Provider>
    );
}
