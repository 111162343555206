import React from "react";
import axios from "axios";
import Footer from "../Components/Footer";
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';

export default class Final extends React.Component {
    state = {
        isSending : true
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        ReactGA.initialize(process.env.REACT_APP_GA);
        ReactGA.send({ hitType: 'pageview', page: '/submit'});

        ReactGA.event({
            category: 'FRO Conversion',
            action: 'FRO Conversion',
            label: 'FRO Conversion'
        });

        const script = document.createElement("script");
        script.src = "https://cdn.myadoptimizer.com/maojsfiles/landingpagesjs/2f739c29-e650-4202-877a-f9624cb5bd9f.js";
        script.async = true;
        document.body.appendChild(script);
        var anura_json = sessionStorage.getItem('anura_final_response');


        const postData = {
            publisher_id : this.props.values.publisher_id,
            upload_type : this.props.values.upload_type,
            server_code : this.props.values.upload_type,
            subid : this.props.values.subid,
            cake_id : "",
            rcid : this.props.values.rcid,
            hid : this.props.values.hid,
            type : this.props.values.type,
            field_31 : window.leadId,
            universal_leadid : window.leadId,
            ipaddress : this.props.values.ipaddress,
            response : "json",
            field_3 : this.props.values.remainingMortgageBalance,
            field_9 : 'Refinance',
            field_8 : "4.35",
            email_address : this.props.values.email,
            talon : document.getElementById('talon').value,
            anura: anura_json,
            field_32 : "https://cert.trustedform.com/"+window.trustedFormId,
            useragent : navigator.userAgent,
            field_1 : this.props.values.homeType,
            field_6 : this.props.values.creditScore,
            field_2: this.props.values.estimatedHomeValue,
            field_4 : this.props.values.remainingMortgageBalance,
            cash_out : this.props.values.cashOut,
            field_28 : this.props.values.activeMilitary,
            field_23 : this.props.values.fhaLoan,
            street_address : this.props.values.address,
            zip : this.props.values.zip,
            city : this.props.values.city,
            state : this.props.values.state,
            first_name: this.props.values.firstName,
            last_name: this.props.values.lastName,
            home_phone: this.props.values.phone,
            field_34 : this.props.values.employmentStatus,
            field_18 : 50000,
            field_19 : 2000
        }

        const formData = new FormData();
        Object.keys(postData).forEach((key) => {
            formData.append(key, postData[key])
        })

        try {
            axios.post(process.env.REACT_APP_API_URL + '/formdov.php', formData).finally((res) => {
                this.setState({
                    isSending: false
                });

                axios.post('https://api.convertkit.com/v3/forms/993490/subscribe', {
                    "api_key": "CMdhSOW0gEh_Ncw1mxAK8g",
                    email: this.props.values.email,
                    first_name: this.props.values.firstName,
                    email_address: this.props.values.email,
                    fields: {
                        first_name: this.props.values.firstName,
                        last_name: this.props.values.lastName,
                        address: this.props.values.address,
                        city: this.props.values.city,
                        state: this.props.values.state,
                        homeowner: 'Y',
                        zip: this.props.values.zip
                    }
                });
            });
        }catch (e) {
            
        }
    }

    render () {
        return (
            <>
                {this.props.values.publisher_id == 1330 &&
                <Helmet>
                    <script>
                        {`
                        gtag('config', 'AW-317447386/S53-CPjJhpMDENq5r5cB', {
                            'phone_conversion_number': '866-505-1308'
                        });
                        gtag('event', 'conversion', {'send_to': 'AW-317447386/_Sn5CNOLwegCENq5r5cB'});
                        `}
                    </script>
                </Helmet>
                }
            <div className="section-question">
                <div className="row question">
                    <div className="col-md-2"/>
                    <div className="col-md-8 text-center">
                        {this.props.values.publisher_id === "1330" ? <h5>Call Us: <a style={{textDecoration: "none"}} href="tel:+18665051308">866-505-1308</a></h5>: ""}
                        <p>Congratulations you have been matched! Click on a lender below!</p>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div id="target" className="text-left"/>
                    </div>
                </div>
            </div>
                <Footer/>
                <div className="m-5 text-center" style={{fontSize:13}}>
                    <div className="mt-2">© <span>{new Date().getFullYear()}</span> FedRateOptions.com</div>
                </div>
            </>
        )
    }
}
