import React from "react";
import Footer from "./Footer";
import Articles from "./Articles";
import ArticleHeader from "./ArticleHeader";

const Layout = ({children}) => {
    return (
        <>
            <ArticleHeader/>
            <div className="section-question section-show">
                {children}
            </div>
            <Articles/>
            <Footer/>
            <div className="m-5 text-center" style={{fontSize:13}}>
                <div className="mt-2">© <span>{new Date().getFullYear()}</span> FedRateOptions.com</div>
            </div>
        </>
    )
}

export default Layout;
