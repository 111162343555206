import React from 'react';
import AdDisclosurePopup from "./AdDisclosurePopup";


const AdvertisingDisclosures = () => {
    return (
        <div className="row justify-content-center mt-5 mb-5">
            <div className="col-auto">
                <AdDisclosurePopup/>
            </div>
        </div>
    );
}

export default AdvertisingDisclosures;