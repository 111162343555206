import React from "react";

export default function OptOut() {
  return (
    <a
      className={"pointer-cursor text-black-50"}
      href="https://www.folderleafsix.com/o-qkbh-n94-5b4a542ca7830ea75bbb6a1bf7d612f1"
      target="_blank"
      rel="noreferrer"
    >
      Opt Out
    </a>
  );
}
