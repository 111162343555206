import React,{useEffect} from "react";

const MortgageRefinance = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    return (
        <>
            <div className="row justify-content-center mt-3">
                <div className="col-md-8">
                    <h5>Mortgage Refinance</h5>
                    
                    <p className="mt-3">The interest rate on a mortgage loan has a big impact on how much more a borrower will pay for their home over its original selling price. As a result, securing the lowest possible interest rate is one of the most important things a borrower can do when purchasing their home.</p>
                    
                    <p>Unfortunately, there are a lot of factors outside of the current national interest rate that can affect a borrower’s rate on their loan. These include things such as their credit history, their down payment, the type of loan they are purchasing, and more. But luckily, if you couldn’t secure the lowest interest rate at the time of your home purchase, eventually, you may have an opportunity to refinance your loan at a lower rate.</p>
                    
                    <p>In this guide, you will learn everything there is to know about refinancing your mortgage.</p>
                    
                    <p><strong>What is a Mortgage Refinance?</strong></p>
                    
                    <p>A mortgage refinance is essentially a new, lower-interest loan on your house that pays off the balance of your previous loan. This type of mortgage product allows a homeowner to take advantage of better terms and lower interest rates without a disruption in their loan payment history.</p>
                    
                    <p><strong>Top Reasons to Refinance a Mortgage</strong></p>
                    
                    <p>There are several reasons why a homeowner might want to consider refinancing their mortgage loan, including:</p>

                    <ul>
                        <li>Lowering their interest rate</li>
                        <li>Lowering their monthly loan payment</li>
                        <li>Eliminating their private mortgage insurance (PMI)</li>
                        <li>Changing their mortgage provider</li>
                        <li>Moving their loan from a variable interest loan to a fixed interest loan (or vice-versa)</li>
                        <li>Switching to a short-term loan</li>
                        <li>Taking cash out with a home equity refinance</li>
                        <li>Removing someone’s name from the loan</li>
                    </ul>

                    <p><strong>What are the Costs Associated with a Mortgage Refinance?</strong></p>
                    
                    <p>In most cases, there will be certain costs the homeowner will have to cover should they decide to refinance their mortgage. Just as with their original loan, they will be required to pay closing costs, which typically average around $2,000, or between 3% and 6% of the total loan amount.</p>
                    
                    <ul>
                        <li>Credit report fee</li>
                        <li>Loan application / loan origination fee</li>
                        <li>Underwriting fee</li>
                        <li>Home appraisal fee</li>
                        <li>Yield-spread premium</li>
                        <li>Title insurance fee</li>
                    </ul>
                    
                    <p>Depending on the lender being used, a borrower may also need to pay a pre-payment penalty fee, which can equal anywhere from one to six months’ worth of interest or a set percentage of the remaining balance on the principal of the loan. Most commonly, pre-payment penalty fees are charged whenever a loan is paid off within three to five years after it was originally taken out. It is important to note that not all lenders charge this fee, so before choosing a lender for your loan, you may want to first inquire.</p>
                    
                    <p>With a refinance, a borrower can also pay for discount points (or mortgage points) if they desire. Each point costs 1% of the loan balance and purchasing them can help reduce your interest rate and lower your monthly payment. The total amount of the points being purchased will be added to the closing costs.</p>
                    
                    <p>Some borrowers may qualify for a no-closing-cost mortgage refinance, which allows the closing costs of the refinanced loan to be folded into their loan amount. In exchange for this convenience, the borrower will usually receive a slightly higher interest rate on their new loan. </p>
                    
                    <p><strong>Pros and Cons of Refinancing a Mortgage</strong></p>
                    
                    <p><em>Advantages of Refinancing a Mortgage:</em></p>

                    <ol>
                        <li><em>Pay off the mortgage faster</em> – If you can already easily afford your loan payment, then refinancing into a shorter loan, such as from a 30-year loan to a 15-year loan, can help you build equity quicker and in many cases, ultimately pay off your loan faster.</li>
                        <li><em>Save money over the life of the loan</em> – Reducing your interest rate will help you save a lot of money over the life of your loan, even if you don’t choose to shorten the length of the loan terms.</li>
                        <li><em>Lower the monthly payment amount</em> – Refinancing at a lower interest rate at the same term as your original loan will extend the time you have to pay off the loan, but in doing so, you will save money on your monthly payment.</li>
                        <li><em>Predictable monthly payments</em> – This is a benefit that you’ll get if you are refinancing an adjustable-rate loan into a fixed-rate loan. Because the rate is fixed, you will pay the same amount every month over the life of the loan.</li>
                        <li><em>Take cash out to cover expenses</em> – If you have equity in your home, refinancing will allow you the ability to take cash out if you need to cover some of life’s larger expenses such as medical costs, college tuition, home improvements, etc.</li>
                    </ol>

                    <p><em>Disadvantages of Refinancing a Mortgage:</em></p>

                    <ol>
                        <li><em>The savings are long-term savings</em> – Most of the savings you will get by refinancing will come later on down the road because of the closing costs you will be required to pay. Therefore, if you are not planning on living in the home for the long-term, you may not reach the break-even point.</li>
                        <li><em>Home equity could be reduced or eliminated</em> – If you are taking cash out with your refinance, then any equity you have already built up in your home will be reduced by the amount you took out or even eliminated entirely.</li>
                        <li><em>Monthly payment amount may increase</em> – If you are refinancing to shorten your loan term, but the interest rate you are getting is not significantly lower than your current one, then your monthly loan payment could wind up being higher than it is right now.</li>
                        <li><em>Longer time to pay off loan</em> – If you have a 30-year loan and you already paid ten years on your current loan, then refinancing into a new 30-year loan will reset the clock and extend the length of time it will take for you to pay off your home by ten more years.</li>
                    </ol>

                    <p><strong>What to Do Before You Refinance Your Mortgage</strong></p>

                    <p>Before you refinance your mortgage, there are a few steps you should take to ensure you are making the best financial decision for your situation.</p>

                    <p>The first thing you should do is to determine the reason for refinancing. For instance, if you want to save money on your monthly payments, be clear about how much money you are looking to save. If you want to take cash out to cover expenses, determine exactly how much you need, so you don’t take more than necessary. When naming your goal for refinancing, be as clear and specific as possible.</p>
                    
                    <p>The next thing you should do is take a deep-dive look into the details of your current mortgage. Find out how much you owe, what your interest rate is, and how many months are left before you pay the loan off. These details are important for analyzing whether a refinance will benefit you.</p>

                    <p>Since the cost and terms of your refinance will be affected by your credit score, you should review your credit and take any steps necessary to help improve it as much as possible before you apply for a refinance.</p>

                    <p>Lastly, before refinancing with your current mortgage provider, take the time to compare several lenders and their mortgage products. Ask each lender for their loan estimate, so you can see all the costs associated with refinancing. This will help ensure you choose the right lender for your new mortgage loan.</p>

                    <p><strong>Is a Mortgage Refinance Right for You?</strong></p>
                    
                    <p>Making the decision to refinance your mortgage should not be a quick one. Ultimately, your choice should be directed by your current loan situation, your refinancing options, and how the closing costs will compare to what you’re spending or saving each month. Do your due diligence and research lenders. Ask plenty of questions to ensure that the terms and rates on the new mortgage will provide you with the benefits you are looking to achieve. Only if the new mortgage will help you reach the goals you have defined for yourself will choosing to refinance be the preferred decision.</p>
                
                </div>
            </div>
        </>
    );
}

export default MortgageRefinance;
