import React from "react";
import ReactBootstrapSlider from 'react-bootstrap-slider';
import Footer from "../Components/Footer";
import CurrencyFormat from 'react-currency-format';
import ReactGA from 'react-ga4';

export default class PropertyValue extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentValue: this.props.values.estimatedHomeValue
        };
    }

    componentDidMount() {
        window.runeHawkTalon();
        
        ReactGA.initialize(process.env.REACT_APP_GA);
        ReactGA.send({ hitType: 'pageview', page: '/propertyvalue'});

        ReactGA.event({
            category: 'Step 2, Property value',
            action: 'Step 2, Property value',
            label: 'Step 2, Property value'
        });
    }

    changeValue = e => {
        this.props.manualHandleChange('estimatedHomeValue',e.target.value)
        this.setState({ currentValue: e.target.value });
    };

    render () {
        return (
            <>
                <form>
                    <input type="hidden" name="universal_leadid" value="" id="leadid_token"/>
            <div className="section-question">
                <div className="row question">
                    <div className="col-md-2"/>
                    <div className="col-md-8 text-center">
                    {this.props.values.publisher_id === "1330" ? <h5>Call Us: <a style={{textDecoration: "none"}} href="tel:+18665051308">866-505-1308</a></h5>: ""}
                        <p>Estimated Property Value</p>
                    </div>
                </div>

                <div className="row mb-2 mt-3">
                    <div className="col-md-2"/>
                    <div className="col-md-8 text-center">
                        <div id="home-purchase-price-value" className="price-value">
                            <CurrencyFormat value={this.state.currentValue<2000000?this.state.currentValue:2000000} displayType={'text'} thousandSeparator={true} prefix={'$'} />{this.state.currentValue<2000000?'':'+'}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-2"/>
                    <div className="col-md-8">
                        <div className="property-price-value">
                            <ReactBootstrapSlider
                                step={15000}
                                max={2010000}
                                min={45000}
                                orientation="horizontal"
                                className="slider"
                                tooltip="hide"
                                value={this.state.currentValue}
                                change={this.changeValue}
                            />
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-2"/>
                    <div className="col-md-8">
                        <span className="range-min">$45,000</span><span className="range-max">$2,000,000+</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4"/>
                    <div className="col-md-4 text-center mt-5 mb-5">
                        <a className="text-black-50 pointer-cursor" style={{paddingRight:20}} onClick={this.props.back}>Back</a>
                        <button type="button" onClick={this.props.next} className="btn-block btn btn-success btn-lg btn-text-white btn-350">Next ></button>
                    </div>
                </div>
            </div>
                </form>
                <Footer/>
                <div className="m-5 text-center" style={{fontSize:13}}>
                    <div className="mt-2">© <span>{new Date().getFullYear()}</span> FedRateOptions.com</div>
                </div>
                </>
        )
    }
}
