import React from 'react';
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfUse from "./TermsOfUse";
import OptOut from "./OptOut";
import { useState } from "react";

const Footer = () => {
    const [isOpenPp, setIsOpenPp] = useState(false);

    function togglePpModal() {
        setIsOpenPp(!isOpenPp);
    }
    return (
        <div className="row justify-content-center mt-5 mb-5">
            <div className="col-auto">
                <PrivacyPolicy isOpenPp={isOpenPp} setIsOpenPp={setIsOpenPp} />
            </div>
            <div className="col-auto">
                <TermsOfUse setIsOpenPp={setIsOpenPp} />
            </div>
            <div className="col-auto">
                <a className={"pointer-cursor text-black-50"} target={'_blank'} href="https://privacyportal-eu-cdn.onetrust.com/dsarwebform/942fbf02-bbfe-4a5d-808d-080b06c5651c/3fdf8f1d-7ab3-4a99-bb4e-24965500290d.html">Do Not Sell My Info</a>
            </div>
            <div className="col-auto">
                <OptOut/>
            </div>
        </div>
    );
}

export default Footer;
