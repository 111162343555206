import React,{useEffect} from "react";

const LoanToValue = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    return (
        <>
            <div className="row justify-content-center mt-3">
                <div className="col-md-8">
                    <h5>Loan-to-Value (LTV) Explained</h5>
                    
                    <p className="mt-3">When you are purchasing your first home, you will be introduced to an entire dictionary of new and sometimes complex words and phrases used in the real estate and financial services industry. One such term is loan-to-value ratio, or LTV. </p>
                    
                    <p>Loan-to-value is a term used by lenders to describe the ratio between a home’s current market value and the amount of the loan being borrowed to purchase it. LTV is one of the benchmarks that lenders use to determine the risk level of the loan transaction before approving it.  </p>
                    
                    <p>As a result, the lower your LTV ratio is, the higher your chances of not only getting approved, but of securing the best rates and terms in your mortgage. </p>
                    
                    <p><strong>How to Calculate LTV</strong></p>
                    
                    <p>Determining the loan-to-value ratio for the property you want to purchase is relatively easy. To calculate your LTV, just divide the amount of your home loan by the property’s most current appraisal. Here is an example: </p>
                    
                    <p>If your lender approves you for a $200,000 loan on a home that’s appraised at $240,000, then you will divide 200,000 by 240,000. In this example, your LTV ratio will be 83%.</p>
                    
                    <p><strong>What Is the Ideal LTV Ratio?</strong></p>
                    
                    <p>The target ratio you want to keep in mind is 80%. If you can get an LTV ratio of 80% or lower, then this will benefit you in a few different ways. For starters, your risk assessment will be lower, so your lender will be more likely to offer you better loan options with lower rates. In addition, an LTV under 80% will also help you potentially save hundreds of dollars on your monthly mortgage payment because you won’t be required to pay private mortgage insurance (PMI). </p>
                    
                    <p>Conversely, this means that if your LTV is higher than 80%, then you will be considered a greater risk by the lender and your monthly mortgage payment will include mortgage insurance. It may also prevent you from securing the best rates and terms on your loan.</p>
                    
                    <p><strong>How to Improve LTV on a New Home Purchase or Refi</strong></p>
                    
                    <p>Loan-to-value is something you need to consider whether you are buying a new home or refinancing your current loan. If you used the equation above and learned that your LTV ratio is higher than 80%, then there are a few things you can do to improve your ratio.</p>
                    
                    <p>If you are purchasing a home and you want to lower your LTV ratio, then making a larger down payment is the most effective way to bring it down. In addition to lowering your LTV and instantly increasing the amount of equity in your home, by investing more of your funds upfront, your lender will see you as a serious and invested borrower, and thus a lower risk.</p>
                    
                    <p>If making a higher down payment isn’t a possibility, then the other option you have is to find a less expensive home. Using the above calculation, you should be able to determine what price range you should be shopping in based on the amount of money you have saved to put down. Or, if you don’t want to give up your dream of buying the home you want, then you can use the calculation to find out how much more money you will need to save or borrow to reach the 80% threshold, so you can get the terms you want and avoid paying PMI.</p>
                    
                    <p>If you want to refinance your current home loan to secure a lower interest rate, the most important thing you can do is continue to make your monthly mortgage payments on time. This will lower your principal balance and increase the amount of equity you have in the home. Ideally, you may not want to refinance until you reach 20% equity in your home. At this point your LTV ratio will be 80% and you will be able to get the best terms on your refi and avoid having to pay mortgage insurance on your new loan (unless you take cash out, which will increase your LTV).</p>
                    
                    <p>Another way to lower your LTV before you refinance is to increase your home’s market value by completing some value-adding home improvement projects. Get your home reappraised once the improvements are complete, so you can see how much sweat equity you gained.</p>
                    
                    <p><strong>What Can Negatively Affect Your LTV Ratio?</strong></p>
                    
                    <p>The housing market is nothing if not fickle and quite often, it can be unpredictable. As a result, should property values drop in your area or something unexpected happen to your home that causes its market value to decrease, your LTV ratio could skyrocket. If the balance of your loan winds up being higher than the appraised value of your home, then this is what is known as being “underwater.” </p>
                    
                    <p>This is a position that no homeowner wants to find themselves in. The best way to avoid the risk is to research home values in the area you want to buy before purchasing to ensure you are not overpaying and continue to make your mortgage payments on time to bring your principal down.  </p>
                    
                    <p><strong>Impact of LTV on Loan Options</strong></p>
                    
                    <p>Loan-to-value ratio plays a substantial role in any home-buying transaction because it is one of the tools used by lenders to determine what types of mortgages a borrower may be eligible for. This is because different loan types have different rules and requirements when it comes to LTV. Here is a breakdown of how LTV affects your loan options:</p>
                    
                    <p><strong>FHA Loans:</strong> FHA loans are one of the most popular options for buyers because these loans tend to have easier approval requirements and they are backed by the Federal Housing Administration, so they are less risky for lenders. Since FHA loans can be obtained with as little as 3.5% down, they are ideal for borrowers with an LTV of 96.5% or lower. However, by putting less than 20% down, you will be required to pay mortgage insurance, but once you reach 20% equity in your home, you can ask your lender to remove the PMI.</p>
                    
                    <p><strong>Conventional Loans:</strong> Conventional loans are mortgages that are not backed by the federal government, so they tend to have stricter qualification requirements. Despite this, however, you can qualify for a conventional loan with an LTV as high as 97%, since this type of loan can be obtained with as little as a 3% down. Again, you will have to pay PMI if you don’t put 20% or more down, or until you reach 20% equity in your home.</p>
                    
                    <p><strong>VA Loans:</strong> VA loans are only available to eligible veterans, active-duty service members, and their surviving spouses and because they are backed by the Department of Veterans Affairs, they tend to have very agreeable terms. They can also be obtained by putting no money down, which means you can have an LTV ratio of 100% with a VA loan.</p>
                    
                    <p><strong>HELOC Loans:</strong> Most lenders will not issue home equity line of credit (HELOC) loans if the borrower’s LTV ratio is higher than 80%. That is not to say that obtaining a HELOC loan isn’t possible with a high LTV, it is simply more difficult to find a lender willing to take the risk.</p>
                    
                    <p>LTV is only one piece of the mortgage puzzle, but it is an important one to consider if you want to get the best rates on your loan and avoid paying mortgage insurance. Understanding the impact LTV will have on your unique situation will help you determine which types of mortgage products might be best suited for your needs or if you should wait a little longer and save a little more money before entering the market. </p>
                </div>
            </div>
        </>
    );
}

export default LoanToValue;
