import React,{useEffect} from "react";

const MortgageRefinanceToLowerPayment = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    return (
        <>
            <div className="row justify-content-center mt-3">
                <div className="col-md-8">
                    <h5>Mortgage Refinance to Lower Payment</h5>
                    
                    <p className="mt-3">The average American household spends $1,275 per month on a 30-year fixed-rate mortgage. But when you take into account all of the different types of mortgages taken out by homeowners, that monthly payment average balloons to over $1,600, according to the most recent data available from the U.S. Census Bureau’s American Housing Survey.</p>
                    
                    <p>For most households, their mortgage payment is their single largest monthly expense and traditionally, it can eat up as much as 30 percent of its monthly income. So, it is understandable why so many homeowners turn to refinancing their mortgages, since securing a lower interest rate is one of the most effective ways of lowering that payment.</p>
                    
                    <p><strong>What is Included in a Mortgage Payment?</strong></p>
                    
                    <p>The standard mortgage payment is a single monthly payment that consists of many elements besides the amount of money that is being applied to the loan’s balance (principal). Traditionally, a mortgage payment is made up of four elements, including the principal, the interest, property taxes, and homeowners’ insurance.</p>
                    
                    <p>If less than 20 percent was put down on the home at the time of purchase, then there will usually be another fee included for private mortgage insurance, or PMI, and if the property is located in a community that’s managed by a homeowner’s association, then HOA fees may also be included in the mortgage payment. It is important to note that certain types of mortgages, such as those guaranteed by the VA or made available through other government programs, can be obtained without PMI.</p>

                    <p>Of all the fees included in a monthly mortgage payment, the only one that the homeowner has any real control over is the interest, and this can be reduced by refinancing the mortgage at a lower rate.</p>

                    <p><strong>How Much Can a Lower Interest Rate Reduce a Mortgage Payment?</strong></p>
                    
                    <p>Refinancing a mortgage at a lower interest rate, even if the reduction isn’t dramatic, can save a homeowner thousands of dollars over the life of their loan. Here is an example</p>

                    <p>A homeowner takes out a 30-year fixed-interest home loan for $200,000 with an interest rate of 4% and a down payment of 20 percent. Their monthly mortgage payment is $1,064. They make their monthly mortgage payments for one year and then decide to refinance. They learn that they can qualify for a mortgage refinance rate of 3.25%, which is just 0.75% lower than their current rate. But with this rate, they can reduce their monthly payment from $1,064 to $997. The $67 a month savings might not seem like a lot but over the life of their loan, they will wind up saving $24,120, minus their refinance closing costs.</p>

                    <p><strong>Other Ways Refinancing Can Help Lower Your Mortgage Payment</strong></p>

                    <p>Besides lowering your interest rate, there are two other ways where refinancing a mortgage can help lower your monthly payment. The first is by refinancing into a longer-term mortgage. For instance, if your original mortgage term was for 15 years, refinancing into a 30-year mortgage will stretch the payments out by an additional 180 months, thus dramatically reducing the amount you are required to pay every month. Of course, the downside to this is it will take twice as long to pay off your home, but if your goal is to make your payment as affordable as possible, then this will certainly help.</p>

                    <p>The other way refinancing can help lower your payment is if you are able to refinance into a mortgage that doesn’t require you to pay PMI. Depending on your loan type, this is usually only possible if you have either put 20 percent or more down on the home or have accumulated at least 20 percent equity in your home. If home values have risen to the point where you have at least 20 percent equity, then refinancing will help you get rid of the extra insurance payment and get into a lower interest rate at the same time.</p>

                    <p><strong>Mortgage Refinance Options by Loan Type</strong></p>

                    <p>Different loan types have different rules when it comes to refinancing. Here is a brief breakdown of the most popular types of refinance options by loan type.</p>

                    <ul>
                        <li><strong>Refinancing an FHA Loan: </strong>If you are at least seven months into your FHA loan and every payment has been made on time, then you can refinance the loan using FHA Streamline. Streamline allows the refinance to be completed with less paperwork and in a shorter time span, but it can only be utilized in an FHA loan to FHA loan refinance. The lender will verify you have the assets available to cover the closing costs, but they won’t need to verify your income or appraise the home.</li>
                        <li><strong>Refinancing a Conventional Loan: </strong>If you have a conventional loan backed by Fannie Mae and Freddie Mac, then you must apply for a refinance the old-fashioned way as there currently is no streamline option available for this type of loan. This means the lender will check your credit, verify your income, and review your debts before approving your refinance application.</li>
                        <li><strong>Refinancing a VA Loan: </strong>VA loans are backed by the U.S. Department of Veterans Affairs. If you want to refinance a VA loan, you can apply for the Interest Rate Reduction Refinance Loan (IRRRL) program. If your refinance is approved, you will need to pay a lender fee that’s equal to 0.5% of the loan amount.</li>
                        <li><strong>Refinancing a USDA Loan: </strong>USDA loans are backed by the U.S. Department of Agriculture and this type of loan can be refinanced using a streamlined assist program. To qualify for the program, your income needs to be below a designated limit, you must have made your last 12 payments on time, and your reduced interest rate must provide you with a savings of at least $50 per month.</li>
                    </ul>

                    <p><strong>Is There a Wrong Time to Refinance?</strong></p>

                    <p>Everyone wants to save money on their mortgage payment, but there are times when a homeowner may want to hold off on refinancing. There are three situations where refinancing might not be the best idea, and these include:</p>

                    <ol>
                        <li><strong>Your Credit Score Dropped – </strong>If you want to refinance a conventional loan, but your credit score recently took a dive, then you should wait to refinance until you can get your score back up, otherwise you won’t get the lower interest rate you were hoping for.</li>
                        <li><strong>Your Debt-to-Income Ratio Has Increased – </strong>The last thing a lender wants to see is an applicant who is overloaded with debt. If your DTI has increased since you took out your original loan, then you probably won’t get approved for your refinance, or if you do, you won’t get the low rate you were aiming for.</li>
                        <li><strong>You are Thinking About Moving – </strong>Refinancing at a lower interest rate helps save money on your monthly payment, but because you have to pay closing costs, those savings may not be realized for some time. Saving money with a refinance is a long-term process; the true savings occur over the life of the loan. Thus, if you are thinking about moving, refinancing now could wind up costing you more money in the long run.</li>
                    </ol>
                    
                    <p>Refinancing your mortgage is an effective way to help you save money, but only if the time is right and you can lower your loan's interest rate substantially. Before applying, consider all your options, think about your future goals, and make sure that now is the right time for you to refinance, so you can enjoy all the benefits that can come from it.</p>
                    
                </div>
            </div>
        </>
    );
}

export default MortgageRefinanceToLowerPayment;
