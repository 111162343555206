import React,{useEffect} from "react";

const BuyingHouseCost = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    return (
        <>
            <div className="row justify-content-center mt-3">
                <div className="col-md-8">
                    <h5>What are the Other Costs Connected with Buying a House</h5>
                    <p className="mt-3">First-time home buyers are not always prepared for the financial reality that comes along with owning a home. While on paper it might look like you can easily afford the home you want, in the real world, there is typically a range of costs connected with buying a house that might catch you unaware if you aren’t careful.</p>
                    <p>From hidden fees only discovered at the closing table to monthly costs that seem to come out of nowhere, there are a variety of other expenses associated with buying a home that not every buyer is prepared for.</p>
                    <p>As a result, before you commit to a mortgage, it is wise to factor in all the other associated costs that you will have cover at closing and over the life of your loan to make sure that you really can afford the home you are buying. In this guide, you will learn more about these costs and what to expect, so you don’t suffer buyer’s remorse.</p>
                    <p><strong>The Down Payment</strong></p>
                    <p>The amount you will have to put down on your home will depend on a range of factors, including the type of mortgage you are applying for. For instance, with an FHA loan, you may be able to put as little as 3.5% down, but if you want to avoid paying private mortgage insurance on your loan, then you will want to put at least 20% down. Your credit score will also impact how much the lender will require for the down payment, so aim to get your score as high as possible before applying.</p>
                    <p><strong>Closing Costs</strong></p>
                    <p>“Closing costs” is technically the umbrella term used to cover a variety of different fees accumulated throughout the home buying process. Typically, closing costs include:</p>
                    <ul>
                        <li>Application fee</li>
                        <li>Appraisal fee</li>
                        <li>Credit check fee</li>
                        <li>State recording fee</li>
                        <li>Origination and/or underwriting fees</li>
                        <li>Title insurance</li>
                        <li>Title search fee</li>
                        <li>Transfer tax (if applicable)</li>
                    </ul>
                    <p>The amount of these fees will vary based on the value of the home and the partners used for each of the services, but in general, you can expect closing costs to equal anywhere from two to five percent of the loan principal.</p>
                    <p><strong>Homeowners Insurance</strong></p>
                    <p>Your home will most likely be the largest investment you will ever make, so you need to protect it. Although homeowners insurance is not required by the law, you will be hard-pressed to find a lender that doesn’t require it. Regardless, you want it to protect you financially from unexpected events should something damage your home. The cost of homeowners insurance varies significantly based on a range of factors, so you should always compare offers to find the best balance of coverage and cost.</p>
                    <p><strong>Private Mortgage Insurance (PMI)</strong></p>
                    <p>Unless you are putting 20% or more down on your home or you are getting VA mortgage, then you will likely be required to pay private mortgage insurance (PMI) in your monthly mortgage payment. PMI is designed to protect the lender should you default on the loan. You should expect your PMI payment to anywhere from 0.58% to 1.86% of the loan amount.</p>
                    <p><strong>Property Tax</strong></p>
                    <p>Depending on where you live, your property tax could account for a larger-than-expected portion of your monthly mortgage payment. The amount you pay in property tax is based on the assessed value of your home (not how much you paid for it) and the tax rate in your area.</p>
                    <p>To find out how much your property tax will be, multiply your home’s assessed value by the tax rate percentage. Then, take that number and divide it by 12 to find out how much your monthly property tax payment will be when it comes due every month.</p>
                    <p><strong>Homeowners Association Fees (HOA)</strong></p>
                    <p>Not every home is overseen by a homeowners association, but if the one you are buying is, then you will probably have to pay a monthly fee to the HOA. These fees can vary wildly based on the area and the services provided by the HOA, and they can be substantial in some cases. Before you consider purchasing a home overseen by an HOA, make sure you find out what fees you will be required to pay because that will increase your monthly obligations.</p>
                    <p><strong>Home Inspection Costs</strong></p>
                    <p>You aren’t obligated to have the home inspected before buying it, but failing to do so could leave you in a financial bind if the home has a major problem that you only discover after moving into it. A typical home inspection costs between $300 and $400, and will need to be paid at the time of inspection.</p>
                    <p><strong>Pest or Mold Inspection</strong></p>
                    <p>A basic home inspection only checks the components of the home that are visible, so if the home you are buying is older, then you may want to opt for a separate pest or mold inspection as well. The costs for this type of inspection varies, but tends to range anywhere from $50 to $300.</p>
                    <p><strong>Home Maintenance, Repairs, and Utilities</strong></p>
                    <p>Being able to afford your mortgage payment is one thing, but add in monthly home maintenance and repair costs and your utilities, and suddenly, you might find you are more cash-strapped than you imagined.</p>
                    <p>Most experts recommend budgeting about one percent of your home’s value for your annual maintenance costs, and that you have an emergency fund set aside, so you can afford to pay any unexpected and urgent repair issues that might arise. Also, before buying a home, it’s important to research the average utility bill for homes of your size in the area, so you can have a realistic idea of what to expect.</p>
                    <p>Owning a home can be one of life’s most rewarding experiences. But the costs of buying and owning a home can add up quickly, so it’s important to be prepared. Before you start the process, save as much money as you can, work to improve your credit, and compare lenders in order to get the best mortgage rates possible.</p>
                </div>
            </div>
        </>
    );
}

export default BuyingHouseCost;
