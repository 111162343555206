import React from "react";
import ScrollToTop from "../Components/ScrollToTop";
import Footer from "../Components/Footer";
import ReactTooltip from 'react-tooltip';
import ReactGA from 'react-ga4';

let FA = require('react-fontawesome')

export default class CashOut extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.runeHawkTalon();

        ReactGA.initialize(process.env.REACT_APP_GA);
        ReactGA.send({ hitType: 'pageview', page: '/cashout'});

        ReactGA.event({
            category: 'Step 5, Cash out',
            action: 'Step 5, Cash out',
            label: 'Step 5, Cash out'
        });
    }

    submitStep = (value) =>{
        this.props.manualHandleChange('cashOut',value)

        this.props.next();

        ScrollToTop();
    }

    render () {
        return (
            <>
                <form>
                    <input type="hidden" name="universal_leadid" value="" id="leadid_token"/>
            <div className="section-question">
                <div className="row question">
                    <div className="col-md-2"/>
                    <div className="col-md-8 text-center">
                        {this.props.values.publisher_id === "1330" ? <h5>Call Us: <a style={{textDecoration: "none"}} href="tel:+18665051308">866-505-1308</a></h5>: ""}
                        <ReactTooltip />
                        <p>Would you like to take cash out? <FA name="question-circle" data-html="true" data-tip="Use your home equity to pull cash out<br/>to pay off credit card bills,<br/>home improvement project, etc"/></p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-1"/>
                    <div className="col-md-10">
                        <div className="buttons cashout">
                            <button className="yes" type="button" onClick={() => this.submitStep('Yes')}>
                                <span className="icon"/>
                                <span className="text">Yes</span>
                            </button>

                            <button className="no" type="button" onClick={() => this.submitStep('No')}>
                                <span className="icon"/>
                                <span className="text">No</span>
                            </button>

                            <button className="maybe" type="button" onClick={() => this.submitStep('Maybe')}>
                                <span className="icon"/>
                                <span className="text">Maybe</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4"/>
                    <div className="col-md-4 text-center mt-2 mb-2">
                        <a className="text-black-50 pointer-cursor" style={{paddingRight:20}} onClick={this.props.back}>Back</a>
                    </div>
                </div>
            </div>
                </form>
                <Footer/>
                <div className="m-5 text-center" style={{fontSize:13}}>
                    <div className="mt-2">© <span>{new Date().getFullYear()}</span> FedRateOptions.com</div>
                </div>
                </>
        )
    }
}
