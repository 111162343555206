import React from 'react';
import {useHistory,useLocation} from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import AdvertisingDisclosures from './AdvertisingDisclosures';
import PropertyTypeHeader from './PropertyTypeHeader';

const ArticleHeader = () => {
    let history = useHistory();
    const { search } = useLocation();

    return (
        <>
            <div className="row justify-content-end" style={{position:"sticky",top:20}}><div className="col-auto pr-5"><div style={{paddingRight:"50px"}}>
                <button type="button" onClick={()=>{history.push("/"+search);ScrollToTop();}} className="btn-block btn btn-success btn-success1 btn-lg btn-text-white btn-scroll">Apply now</button>
            </div></div></div>
        <section id="header">
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12 text-center">
                            <div id="logo" className="text-center" style={{cursor:"pointer"}}><a onClick={()=>{history.push("/");}}/></div>
                        </div>
                    </div>
                </div>
                <PropertyTypeHeader />
                <AdvertisingDisclosures/>
            </header>
        </section>
        </>
    );
}

export default ArticleHeader;
