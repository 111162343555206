import React from "react";
import ScrollToTop from "../Components/ScrollToTop";
import RefinanceBlock from "../Components/RefinanceBlock";
import Footer from "../Components/Footer";
import ReactGA from 'react-ga4';
import Articles from "../Components/Articles";
import AdvertisingDisclosures from "../Components/AdvertisingDisclosures";
import PropertyTypeHeader from "../Components/PropertyTypeHeader";

export default class PropertyType extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.runeHawkTalon();

        ReactGA.initialize(process.env.REACT_APP_GA);
        ReactGA.send({ hitType: 'pageview', page: '/propertytype'});

        ReactGA.event({
            category: 'FRO Landing',
            action: 'FRO Landing',
            label: 'FRO Landing'
        });
    }

    submitStep = (value) =>{
        this.props.manualHandleChange('homeType',value)

        this.props.next();

        ScrollToTop();
    }

    scrollTop = (value) =>{
        ScrollToTop();
    }

    render () {
        return (
            <form>
                <input type="hidden" name="universal_leadid" value="" id="leadid_token"/>
            <div className="section-question section-show">

                <PropertyTypeHeader />
                <AdvertisingDisclosures/>
                <RefinanceBlock/>
                
                        <div>
                            <button type="button" onClick={this.scrollTop} className="btn-block btn btn-success btn-success1 btn-lg btn-text-white btn-scroll">Refinance My Mortgage</button>
                        </div>
                        <div className="m-5 text-center" style={{fontSize:13}}>
                <div><p>*POTENTIALLY LOWER YOUR OVERALL MONTHLY PAYMENTS - <a href="https://www.fool.com/the-ascent/mortgages/refinance-pay-off-debt/" target="_blank">Motley Fool</a></p></div>
                <div className="mt-2"><p>FedRateOptions.com an RGR Marketing website provides marketing services for various companies involved in mortgage refinance. The information you provide to us is provided to these companies in real time. If the information you provide to us matches the criteria they are seeking, they will contact you directly. In many cases we may deploy SMS to you to facilitate an inbound call to the service or product provider. We are paid by such providers for each consumer they contact directly and/or provide services or products. You are not charged for our services. We do not guarantee that a provider will accept your request or that their products or services will meet your needs. Their services and products may or may not be the best product or service available on the market. Completing our forms does not obligate you to purchase a service or product nor does it obligate a product or service provider to provide you with any particular service about which you may have inquired. You must be a legal resident of the United States to use this Website and we specifically prohibit access or use by residents of other countries including but not limited to Canadian and European Union Member Citizens. <a href="https://www.nmlsconsumeraccess.org" target="_blank">Nationwide Mortgage Licensing System (NMLS) ID # 2721.</a></p></div>
                <div className="mt-2"><a href="http://www.refiexpert.net" target="_blank">Licenses and Disclosures</a></div>
                <div className="mt-2">© <span>{new Date().getFullYear()}</span> FedRateOptions.com</div>
            </div>
                <Articles/>
                <Footer/>

            </div>
            </form>
        )
    }
}
