import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import parse from "html-react-parser";

export default function TermsOfUse({setIsOpenPp}) {
    const [isOpen, setIsOpen] = useState(false);
    const [content,setContent] = useState(null);
    const customStyles = {
        content: {
          width: '90%',
          height: '70%',
        },
    };

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    function togglePpModal() {
        setIsOpenPp(true);
        setIsOpen(false);
    }

    const handleClick = $event => {
        if ($event.target.id === 'ppTag') {
            togglePpModal();
        }
    };

    useEffect(() => {
        fetch('https://api.reallygreatrate.com/landingpagecontent/terms-of-use-refinance', {
            method: 'POST',
            body: JSON.stringify({
                sitename:"FedRateOptions.com",
                ppLink: ''
            }),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          })
             .then((response) => response.json())
             .then((resp) => {
                setContent(resp.data.html);
             })
             .catch((err) => {
                console.log(err.message);
             });
    }, []);

    return (
        <>
            <a className={"pointer-cursor text-black-50"} onClick={toggleModal}>Terms of Use</a>
            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="Terms of use"
                className="contentModal"
                overlayClassName="contentModalOverlay"
                ariaHideApp={false}
                style={customStyles}
            >
                <div className="contentModalHeader">
                    <div className="row mt-3">
                        <div className="col">
                            <h3>TERMS OF USE</h3>
                        </div>
                        <div className="col-auto text-right">
                            <a className="pointer-cursor text-white" onClick={toggleModal}>Close</a>
                        </div>
                    </div>
                </div>
                <div className="contentModalText" onClick={ ($event) => handleClick($event) }>
                    {content &&
                        parse(content)
                    }
                </div>
            </Modal>
        </>
    );
}
