const initialState = {
    step: 1,
    signupSuccess: false,
    fullAddress: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    apt: "",
    homeType: "",
    creditScore: "",
    estimatedHomeValue: 270000,
    remainingMortgageBalance: 210000,
    cashOut: "",
    activeMilitary: "",
    fhaLoan: "",
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    ipaddress: "",
    publisher_id: "",
    upload_type: "FRO",
    subid: "",
    cake_id: "",
    rcid: "",
    hid: "",
    type: "REFINANCE",
    employmentStatus: "",
    incorrectFields: [],
    monthNames : ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"],
};

function rootReducer(state = initialState, action) {
    if (action.type === 'FORM') {
        return Object.assign({}, state, action.payload);
    }
    return state;
}

export default rootReducer;
