import React,{useEffect} from "react";

const FHARefinance = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    return (
        <>
            <div className="row justify-content-center mt-3">
                <div className="col-md-8">
                    <h5>FHA Refinance</h5>
                    
                    <p className="mt-3">An FHA home loan is a mortgage that is insured by the Federal Housing Administration. With down payments as low as 3.5% and credit score requirements as low as 580 FICO, FHA loans are among the most popular types of mortgages taken out by first-time homeowners in order to purchase their homes.</p>
                    
                    <p>If you currently have an FHA home loan and you are thinking about refinancing it to secure a lower rate or to take cash out of your home’s equity, then there are three options open to you if you want to stay with an FHA insured loan. These options include the FHA Streamline Refinance, the FHA Cash-Out Refinance, and the FHA Simple Refinance, which we discuss in greater depth in this article.</p>

                    <p><strong>FHA Streamline Refinance</strong></p>
                    
                    <p>The FHA Streamline Refinance allows borrowers to save time and money on their refinance because it can be obtained with little-to-no additional paperwork, and in most cases, without the need for a home appraisal. In many cases, the lender won’t even need to check the borrower’s credit score to qualify. Because of these details, it is regarded as the quickest and easiest way to secure a lower interest rate on an existing FHA home loan.</p>
                    
                    <p>But that’s not to say that there aren’t other eligibility requirements that need to be met. For instance, for a homeowner to be eligible for this type of refinance, they must:</p>

                    <ul>
                        <li>Have an existing FHA-insured home loan older than 210 days</li>
                        <li>Be current in their mortgage payments for at least the last six months</li>
                        <li>Receive a tangible benefit by refinancing</li>
                    </ul>

                    <p>While it is possible for a homeowner with a conventional loan to be refinanced into a mortgage backed by the FHA, the Streamline option is only available to borrowers who have an existing FHA loan. To refinance into an FHA-backed loan, a conventional loan owner would need to apply for an FHA Cash-Out Refinance.</p>

                    <p><strong>FHA Cash-Out Refinance</strong></p>
                    
                    <p>An FHA Cash-Out Refinance can be beneficial to homeowners looking to tap into their home equity to pay for things like home improvements, college tuition, unpaid medical expenses, or even credit card debt. This type of refinance is especially attractive to those whose property has increased in market value since they purchased their home. As a result, a borrower needs to have at least 20% equity in their home in order to qualify.</p>
                    
                    <p>Unlike with an FHA Streamline Refinance, applying for a Cash-Out Refinance requires many of the same thresholds to be met as needed when applying for a first mortgage, including:</p>
                    
                    <ul>
                        <li>A minimum credit score of 580</li>
                        <li>A debt-to-income ratio of 50% or less</li>
                        <li>A maximum loan-to-value of 80% of the home's current value</li>
                        <li>Documentation to prove that the borrower has made all the monthly payments for the previous 12 months or that they own the home free and clear</li>
                        <li>Documentation to prove that the home is the borrower’s primary residence and that they have lived there for at least the last 12 months</li>
                    </ul>
                    
                    <p>To qualify for a Cash-Out Refinance, a borrower will also need to have their income verified by the lender and the property re-appraised.</p>
                    
                    <p><strong>FHA Simple Refinance</strong></p>

                    <p>An FHA Simple Refinance is a good choice for homeowners if they want to refinance their current FHA loan into a new FHA loan, and it can be used whether the loan is a fixed-rate loan or an ARM. With the Simple Refinance, there is no option for taking cash-out and lenders will require the homeowner to meet certain credit, income, and asset qualifications. A new home appraisal is also required of borrowers in order to qualify.</p>
                    
                    <p>FHA Simple Refinance loans can be attractive to some homeowners because they allow the borrower to fold their closing costs and any other pre-paid items right into the loan. They also allow for borrowers to finance up to 97.5% of the adjusted value of their property.</p>
                    
                    <p><strong>What are the Fees Associated with an FHA Refinances?</strong></p>

                    <p>Regardless of whether you apply for an FHA Streamline Refinance loan or Cash-Out Refinance, you will be charged an upfront mortgage insurance premium equal to 1.75% of your new loan amount and be required to pay closing costs, although the mortgage insurance (PMI) can be rolled into the loan at the borrower’s request. Of course, taking this action will likely result in a higher monthly payment.</p>

                    <p>Unlike with a conventional mortgage, which allows you to have the mortgage insurance removed if you put down 20% or more or reach 20% equity in your home, PMI will usually be included in the FHA loan’s monthly payment for the entirety of the loan’s term. The only way to avoid paying PMI when refinancing an FHA loan is to refinance it into a conventional mortgage with a loan-to-value ratio of 80% or lower.</p>

                    <p><strong>Is an FHA Refinance Right for You?</strong></p>

                    <p>Whether you want to lock in a lower interest rate or take cash out against the equity that you’ve built up in your home, the FHA offers a variety of different refinance products that can help you reach your goals. If your current loan is an FHA home loan, then refinancing using the FHA’s Streamline Refinance will probably be your quickest and easiest path to savings. If you want to take cash out, then you should expect the vetting process to be a little more thorough. However, if your financial situation and credit health haven’t dropped or diminished since you obtained your original loan and your loan meets the lender’s LTV standard requirements, then you should not have a problem qualifying for a cash-out refinance.</p>

                    <p>As with any refinance, you should start by shopping around to see what’s available, and comparing lenders. This is because each lending institution will have their own unique qualification requirements and loan limits, which could impact your choice. By filling out a couple of applications with a few of the top mortgage lenders in your area, you can quickly get a better idea of what you might qualify for and just how much you might be able to save. </p>

                </div>
            </div>
        </>
    );
}

export default FHARefinance;
